import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  Component,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { createPopper, Instance, Options } from '@popperjs/core';

@Directive({
  selector: `[pastureTooltip]`,
})
export class TooltipDirective implements OnDestroy {
  private readonly defaultColor = `grey`;

  @Input() pastureTooltip: TemplateRef<any>;
  @Input() config: Partial<Options> = {
    placement: `top`,
  };
  @Input() tooltipColor: string;
  @Input() duration = 200;
  @Input() offset = 0;

  private popperInstance: Instance;
  private tooltipComponent: ComponentRef<TooltipWrapperComponent>;

  constructor(protected readonly el: ElementRef, protected readonly viewContainer: ViewContainerRef) {}

  @HostListener(`mouseenter`) protected enter(): void {
    this.createTooltip();
  }

  @HostListener(`mouseleave`, [`$event`]) protected leave(event: MouseEvent): void {
    if (event.relatedTarget && this.tooltipComponent.location.nativeElement.contains(event.relatedTarget)) {
      return;
    }
    this.destroyTooltip();
  }

  ngOnDestroy(): void {
    this.destroyTooltip();
  }

  private createTooltip(): void {
    this.destroyTooltip();
    this.tooltipComponent = this.viewContainer.createComponent(TooltipWrapperComponent);
    this.tooltipComponent.setInput(`template`, this.pastureTooltip);
    this.tooltipComponent.setInput(`tooltipColor`, this.tooltipColor ?? this.defaultColor);
    this.tooltipComponent.setInput(`duration`, this.duration);
    this.tooltipComponent.setInput(`offset`, this.offset);
    this.tooltipComponent.changeDetectorRef.detectChanges();
    this.tooltipComponent.location.nativeElement.addEventListener(`mouseleave`, (event) => {
      if (event.relatedTarget && this.el.nativeElement.contains(event.relatedTarget)) {
        return;
      }
      this.destroyTooltip();
    });
    this.popperInstance = createPopper(
      this.el.nativeElement,
      this.tooltipComponent.location.nativeElement,
      this.config
    );
    document.body.appendChild(this.tooltipComponent.location.nativeElement);
  }

  private destroyTooltip(): void {
    this.popperInstance?.destroy();
    this.tooltipComponent?.destroy();
  }
}

@Component({
  template: `
    <div
      class="tooltip-wraper"
      [style.backgroundColor]="tooltipColor"
      [@fadeIn]="{ value: animationState, params: { duration: duration, offset: offset } }"
    >
      <ng-container *ngTemplateOutlet="template"></ng-container>
      <div class="arrow" data-popper-arrow></div>
    </div>
  `,
  styles: [
    `
      :host {
        z-index: 100;
      }
      .tooltip-wraper {
        padding: 8px;
        border-radius: 8px;
      }
      :host[data-popper-placement^='top'] .arrow {
        bottom: -4px;
      }

      :host[data-popper-placement^='bottom'] .arrow {
        top: -4px;
      }

      :host[data-popper-placement^='left'] .arrow {
        right: -4px;
      }

      :host[data-popper-placement^='right'] .arrow {
        left: -4px;
      }

      .arrow,
      .arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
      }

      .arrow {
        visibility: hidden;
      }

      .arrow::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
      }
    `,
  ],
  animations: [
    trigger(`fadeIn`, [
      state(`void`, style({ opacity: 0 })),
      transition(`void => *`, [animate(`{{duration}}ms {{offset}}ms`, style({ opacity: 1 }))]),
    ]),
  ],
  imports: [CommonModule],
  standalone: true,
})
class TooltipWrapperComponent implements OnInit {
  @Input() template: TemplateRef<any>;
  @Input() tooltipColor: string;
  @Input() duration: number;
  @Input() offset: number;
  public animationState = `void`;

  ngOnInit(): void {
    this.animationState = `active`;
  }
}
