import { Pipe, PipeTransform } from '@angular/core';
import { UnitPersistencyService } from './unit-persistency.service';

@Pipe({
  name: `unitName`,
})
export class UnitNamePipe implements PipeTransform {
  constructor(private unitSerice: UnitPersistencyService) {}

  transform(value: SupportedUnits): string {
    const shouldTransform = this.unitSerice.unit !== `imperial`;

    if (!shouldTransform) {
      return value;
    }

    switch (value) {
      case `lb`:
        return `kg`;
      case `in`:
        return `cm`;
      case `ac`:
        return `ha`;
      case `lb/ac`:
        return `kg/ha`;
      default:
        return value;
    }
  }
}

type SupportedUnits = `lb` | `in` | `ac` | `lb/ac`;
