import { RainfallEvent } from 'src/app/shared/models/rainfall-event.interface';
import { RainfallStation } from 'src/app/shared/models/rainfall-station.interface';

export interface State {
  rainfall: Array<RainfallEvent>;
  rainfallLoading: boolean;
  rainfallStations: Array<RainfallStation>;
  rainfallStationsLoading: boolean;
}

export const initialState: State = {
  rainfall: [],
  rainfallLoading: false,
  rainfallStations: [],
  rainfallStationsLoading: false,
};
