import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of } from 'rxjs';
import { Store } from '@ngrx/store';

import { RanchService } from '../services/ranch.service';
import * as RanchActions from './ranch.actions';
import { Paddock, PaddockHistory, RecordType, SingleHerdPaddockHistory } from 'src/app/shared/models/paddock.interface';
import { GrazeEvent } from 'src/app/shared/models/graze-event.model';
import { MarginBetweenEvents, MsInDay } from 'src/app/shared/constants/time.constant';
import { selectPaddocks } from './ranch.selectors';

@Injectable()
export class RanchEffects {
  getRanch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RanchActions.GetRanch),
      mergeMap(() =>
        this.ranchService.getRanch().pipe(
          mergeMap((ranch) => {
            return of(RanchActions.GetRanchSuccess({ ranch }));
          }),
          catchError(() => of(RanchActions.GetRanchFailure()))
        )
      )
    );
  });

  getPaddocks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RanchActions.GetPaddocks),
      mergeMap(() =>
        this.ranchService.getPaddocks().pipe(
          mergeMap((paddocks) => {
            return of(RanchActions.GetPaddocksSuccess({ paddocks }));
          }),
          catchError(() => of(RanchActions.GetPaddocksFailure()))
        )
      )
    );
  });

  getGrazingEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RanchActions.GetPaddocksSuccess),
      mergeMap((action) => {
        const { planned, historical, other, possibleMoveDates } = this.calculateGrazingEvents(
          action.paddocks.flatMap((p) => p.history)
        );
        return of(
          RanchActions.GrazeEventsCalculated({
            plannedEvents: planned,
            historicalEvents: historical,
            otherPaddockEvents: other,
            earliestPossibleMovePerHerd: possibleMoveDates,
          })
        );
      })
    );
  });

  getSeasons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RanchActions.GetSeasons),
      mergeMap(() =>
        this.ranchService.getSeasons().pipe(
          mergeMap((seasons) => {
            seasons.sort((a, b) => new Date(a.start_date).valueOf() - new Date(b.start_date).valueOf());
            return of(RanchActions.GetSeasonsSuccess({ seasons }));
          }),
          catchError(() => of(RanchActions.GetSeasonsFailure()))
        )
      )
    );
  });

  getInfrastrucures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RanchActions.GetInfrastructures),
      mergeMap(() =>
        this.ranchService.getInfrastructures().pipe(
          mergeMap((infrastructures) => of(RanchActions.GetInfrastructuresSuccess({ infrastructures }))),
          catchError(() => of(RanchActions.GetInfrastructuresFailure()))
        )
      )
    );
  });

  updatePaddocksHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RanchActions.UpdatePaddocksHistory),
      concatLatestFrom(() => this.store.select(selectPaddocks)),
      mergeMap(([action, currentPaddocks]) => {
        if (!action.events && !action.eventsToDelete) {
          return of();
        }
        const events = action.events ?? [];
        const eventsToDelete = action.eventsToDelete ?? [];

        const paddocks = structuredClone(currentPaddocks);
        const relevantPaddockIds = [...events, ...eventsToDelete].map((e) => e.paddock_uuid);
        const modifiedPaddocks = paddocks.filter((p) => relevantPaddockIds.includes(p.uuid));
        const paddockMap = modifiedPaddocks.reduce(
          (map, paddock) => map.set(paddock.uuid, paddock),
          new Map<string, Paddock>()
        );
        if (events.length) {
          this.updatePaddocksHistory(paddocks, events, paddockMap);
        }
        if (eventsToDelete.length) {
          this.deletePaddocksHistory(eventsToDelete, paddockMap);
        }

        modifiedPaddocks.forEach((paddock) =>
          paddock.history.sort((a, b) => b.record_date.localeCompare(a.record_date))
        );

        return of(RanchActions.GetPaddocksSuccess({ paddocks }));
      })
    );
  });

  constructor(
    private actions$: Actions,
    private ranchService: RanchService,
    private store: Store
  ) {}

  private calculateGrazingEvents(paddocksHistory: PaddockHistory[]): {
    planned: Array<GrazeEvent>;
    historical: Array<GrazeEvent>;
    other: Array<SingleHerdPaddockHistory>;
    possibleMoveDates: { [id: string]: number };
  } {
    const moves: SingleHerdPaddockHistory[] = paddocksHistory
      .filter((h) => h.record_type === RecordType.MoveIn || h.record_type === RecordType.MoveOut)
      .reverse()
      .flatMap((point) => point.herd_snapshots.map((herd) => ({ ...point, herd_snapshots: herd })));

    const movesByHerd: { [herdUuid: string]: SingleHerdPaddockHistory[] } = moves.reduce((prev, curr) => {
      prev[curr.herd_snapshots.herd_uuid]
        ? prev[curr.herd_snapshots.herd_uuid].push(curr)
        : (prev[curr.herd_snapshots.herd_uuid] = [curr]);
      return prev;
    }, {});

    const otherEvents = paddocksHistory
      .map((h) => ({ ...h, herd_snapshots: null }))
      .filter((event) => event.record_type !== RecordType.MoveIn && event.record_type !== RecordType.MoveOut);
    const plannedEvents: GrazeEvent[] = [];
    const historicalEvents: GrazeEvent[] = [];
    Object.entries(movesByHerd).forEach(([_, events]) => {
      let grazeEvent = new GrazeEvent();
      events.sort(
        (a, b) => Date.parse(a.record_date) - Date.parse(b.record_date) - Number(a.record_type === RecordType.MoveOut)
      );
      events.forEach((event) => {
        if (event.task_details && !event.task_details.datetime_completed) {
          const plannedEvent = new GrazeEvent();
          plannedEvent.start = event;
          plannedEvents.push(plannedEvent);
          return;
        }
        if (grazeEvent.start) {
          if (event.record_type === RecordType.MoveOut && event.paddock_uuid === grazeEvent.start.paddock_uuid) {
            grazeEvent.end = event;
            historicalEvents.push(grazeEvent);
            grazeEvent = new GrazeEvent();
          }
        } else {
          if (event.record_type === RecordType.MoveIn) {
            grazeEvent.start = event;
          }
        }
      });
      if (grazeEvent.start && !grazeEvent.end) {
        historicalEvents.push(grazeEvent);
      }
    });

    historicalEvents.forEach((element) => {
      if (
        !element.end &&
        element.start.grazing_time &&
        !this.herdInEvents(element.start.herd_snapshots.herd_uuid, plannedEvents)
      ) {
        const end = { ...element.start };
        end.record_date = new Date(
          Date.parse(element.start.record_date) + element.start.grazing_time * MsInDay
        ).toISOString();
        end.record_type = RecordType.MoveOut;
        plannedEvents.push(new GrazeEvent(element.start, end));
      }
    });

    plannedEvents.sort((a, b) => Date.parse(a.start.record_date) - Date.parse(b.start.record_date));
    historicalEvents.sort((a, b) => Date.parse(a.start.record_date) - Date.parse(b.start.record_date));
    otherEvents.sort((a, b) => Date.parse(a.record_date) - Date.parse(b.record_date));

    const possibleMoveDates = this.earliestPossibleMovePerHerd(historicalEvents);

    return { planned: plannedEvents, historical: historicalEvents, other: otherEvents, possibleMoveDates };
  }

  private herdInEvents(herdId: string, events: GrazeEvent[]): boolean {
    return events.map((e) => e.start.herd_snapshots.herd_uuid).includes(herdId);
  }

  private earliestPossibleMovePerHerd(historicalEvents: GrazeEvent[]): { [id: string]: number } {
    const result = {};
    [...historicalEvents].reverse().forEach((event) => {
      const herdId = event.start.herd_snapshots.herd_uuid;
      if (!result[herdId]) {
        if (event.end) {
          const value = Date.parse(event.end.record_date);
          result[herdId] = value;
        } else {
          const value = Date.parse(event.start.record_date) + MarginBetweenEvents;
          result[herdId] = value;
        }
      }
    });
    return result;
  }

  private updatePaddocksHistory(paddocks: Paddock[], events: PaddockHistory[], paddockMap: Map<string, Paddock>): void {
    const allEvents = paddocks.map((p) => p.history).flat();
    events.forEach((event) => {
      const paddock = paddockMap.get(event.paddock_uuid);
      const existingEventIndex = paddock.history.findIndex((e) => e.uuid === event.uuid);
      if (existingEventIndex !== -1) {
        paddock.history[existingEventIndex] = event;
        return;
      }
      const relevantPhp = allEvents.find((php) => php.uuid === event.uuid);
      if (relevantPhp) {
        const currentPaddock = paddocks.find((p) => p.uuid === relevantPhp.paddock_uuid);
        currentPaddock.history = currentPaddock.history.filter((php) => php.uuid !== event.uuid);
      }
      paddock.history.push(event);
    });
  }

  private deletePaddocksHistory(events: PaddockHistory[], paddockMap: Map<string, Paddock>): void {
    events.forEach((event) => {
      const paddock = paddockMap.get(event.paddock_uuid);
      const existingEventIndex = paddock.history.findIndex((e) => e.uuid === event.uuid);
      if (existingEventIndex !== -1) {
        paddock.history.splice(existingEventIndex, 1);
      }
    });
  }
}
