export const ColorsModel: Array<Color> = [
  {
    name: `Red`,
    value: `#cb0100`,
  },
  {
    name: `Orange`,
    value: `#e77131`,
  },
  {
    name: `Yellow`,
    value: `#efaf33`,
  },
  {
    name: `Green`,
    value: `#229959`,
  },
  {
    name: `Blue`,
    value: `#0070e5`,
  },
  {
    name: `Pink`,
    value: `#e25d9d`,
  },
  {
    name: `Purple`,
    value: `#6538c1`,
  },
  {
    name: `Clear`,
    value: `#4d5354`,
  },
];

export type Color = {
  name: string;
  value: string;
};
