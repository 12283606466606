<div class="field-container" [ngClass]="edit ? 'open' : 'close'">
  @switch (type) {
    @case ('default') {
      <mat-form-field [appearance]="apperance">
        <input matInput [value]="value" [readonly]="!edit" [placeholder]="placeholder" />
      </mat-form-field>
    }
    @case ('date') {
      <mat-form-field [appearance]="apperance">
        <input matInput [value]="value" [matDatepicker]="picker" [readonly]="!edit" [placeholder]="placeholder" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker touchUi></mat-datepicker>
      </mat-form-field>
    }
    @case ('textarea') {
      <mat-form-field [appearance]="apperance">
        <textarea
          matInput
          cdkTextareaAutosize
          [value]="value"
          [readonly]="!edit"
          [placeholder]="placeholder"
        ></textarea>
      </mat-form-field>
    }
  }
</div>
