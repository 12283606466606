import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';

import { AlertService } from '../../shared/alert/alert.service';
import { LoginModel } from './login.model';
import { AuthService } from '../../shared/auth/auth.service';
import { CustomValidators } from 'src/app/shared/form-validators/custom-validators.class';
import { EventTrackerService } from 'src/app/shared/services/event-tracker.service';
import { HashingService } from '../../shared/helpers/hashing.service';

@Component({
  selector: `pasture-login`,
  templateUrl: `./login.component.html`,
  styleUrls: [`./login.component.scss`],
})
export class LoginComponent implements OnInit {
  public isLoading: boolean;
  public loginForm: FormGroup;
  private returnUrl: string;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private readonly hashingService: HashingService,
    private router: Router,
    private route: ActivatedRoute,
    private eventTracker: EventTrackerService
  ) {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl(``, [Validators.required, CustomValidators.email]),
      password: new FormControl(``, [Validators.required, Validators.maxLength(100)]),
    });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || `/onboarding/step-1`;
  }

  public async login(user: LoginModel): Promise<void> {
    if (this.loginForm.valid) {
      this.eventTracker.push({
        event: `login`,
        cp_product_name: `PastureMap`,
        cp_user_email: await this.hashingService.sha256(user.email),
      });
      this.isLoading = true;
      this.authService
        .login(user)
        .pipe(switchMap(() => this.authService.getMe()))
        .subscribe({
          next: () => {
            this.router.navigate([this.returnUrl]);
          },
          error: (errResponse: any) => {
            this.isLoading = false;
            this.alertService.showError(errResponse.error.details, ``);
          },
        });
    }
  }

  public hasError = (controlName: string, errorName: string): boolean => {
    return this.loginForm.controls[controlName].hasError(errorName);
  };
}
