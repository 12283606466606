<div class="this-wrapper">
  <div class="main-container">
    <h1>Your Browser is Old!</h1>
    <h2>
      You are using very old and not supported browser <br />
      Please use the latest version of Google Chrome for better experience
    </h2>
    <br />
    <img class="old-cow" src="assets/img/old_cow.svg" alt="Old Cow" />
    <br />
    <a class="download-button" href="https://www.google.com/chrome/" target="_blank" mat-raised-button color="primary"
      >Download Google Chrome</a
    >
  </div>
</div>
