import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { GrazeEvent } from 'src/app/shared/models/graze-event.model';
import { Infrastructure } from 'src/app/shared/models/inventory.interface';
import { Paddock, PaddockHistory } from 'src/app/shared/models/paddock.interface';
import { Season } from 'src/app/shared/models/season.interface';
import { State } from './ranch.state';
import { RootState } from '../..';
import { MsInDay } from 'src/app/shared/constants/time.constant';

const selectRanchState = (rootState: RootState): State => rootState.ranch.ranchState;

export const selectRanch = createSelector(selectRanchState, (state: State) => state.ranch);
export const selectAnimalUnit = createSelector(selectRanch, (ranch) => ranch.standard_animal_unit);
export const selectDailyFeedPercent = createSelector(selectRanch, (ranch) => ranch.standard_daily_feed_intake_percent);
export const selectHerdsLocation = createSelector(selectRanchState, (state: State) => herdsLocation(state));
export const selectSpecificHerdsLocation = (
  herdIds: string[]
): MemoizedSelector<RootState, HerdLocation[], DefaultProjectorFn<HerdLocation[]>> =>
  createSelector(selectHerdsLocation, (locations: HerdLocation[]) =>
    locations.filter((l) => herdIds.includes(l.herdId))
  );
export const selectHerdLocation = (herdId: string): MemoizedSelector<RootState, Paddock, DefaultProjectorFn<Paddock>> =>
  createSelector(selectHerdsLocation, (herdsLocation) => herdsLocation.find((herd) => herd.herdId === herdId)?.paddock);
export const selectPaddocks = createSelector(selectRanchState, (state: State) => state.paddocks);
export const selectPaddocksLoaded = createSelector(selectRanchState, (state: State) => state.paddocksLoaded);
export const selectActivePaddocks = createSelector(selectPaddocks, (paddocks) => paddocks.filter((p) => p.is_active));
export const selectPaddockNames = createSelector(selectRanchState, (state: State) =>
  state.paddocks.map((p) => ({ id: p.uuid, name: p.identification }))
);
export const selectSeasons = createSelector(selectRanchState, (state: State) => state.seasons);
export const selectInfrastructures = createSelector(selectRanchState, (state: State) => state.infrastructures);
export const selectGrazeEvents = createSelector(selectRanchState, (state: State) => state.historicalEvents);
export const selectPlannedEvents = createSelector(selectRanchState, (state: State) => state.plannedEvents);
export const selectOtherPaddockEvents = createSelector(selectRanchState, (state: State) => state.otherPaddockEvents);
export const selectGrazeEventsCalculated = createSelector(
  selectRanchState,
  (state: State) => state.grazeEventsCalculated
);

export const selectRanchArea = createSelector(selectPaddocks, (paddocks) =>
  paddocks.reduce((acc, cur) => acc + cur.area, 0)
);
export const selectPaddock = (paddockId: string): MemoizedSelector<any, Paddock, DefaultProjectorFn<Paddock>> =>
  createSelector(selectPaddocks, (paddocks) => paddocks.find((p) => p.uuid === paddockId));
export const selectPaddockName = (paddockId: string): MemoizedSelector<Paddock, string, DefaultProjectorFn<string>> =>
  createSelector(selectPaddock(paddockId), (paddock) => paddock?.identification);

export const selectInfrastructure = (
  objectId: string
): MemoizedSelector<any, Infrastructure, DefaultProjectorFn<Infrastructure>> =>
  createSelector(selectInfrastructures, (infrastructures) => infrastructures.find((p) => p.uuid === objectId));

export const selectSeason = (currentSeasonId: string): MemoizedSelector<any, Season, DefaultProjectorFn<Season>> =>
  createSelector(selectSeasons, (seasons: Array<Season>): Season => {
    if (!seasons || seasons.length === 0) {
      return null;
    }
    const seasonIndex = seasons.findIndex((s) => s.uuid === currentSeasonId);
    return seasonIndex === -1 ? seasons[seasons.length - 1] : seasons[seasonIndex];
  });

export const selectPreviousSeason = (
  currentSeasonId: string
): MemoizedSelector<any, Season, DefaultProjectorFn<Season>> =>
  createSelector(selectSeasons, selectSeason(currentSeasonId), (seasons, current): Season => {
    if (current && seasons.length >= 2) {
      const indexOfCurrentSeason = seasons.findIndex((s) => s.uuid === current.uuid);
      return seasons[indexOfCurrentSeason - 1];
    }
    return null;
  });

export const selectGrazeEventsForPaddock = (
  paddockId: string
): MemoizedSelector<any, Array<GrazeEvent>, DefaultProjectorFn<Array<GrazeEvent>>> =>
  createSelector(selectGrazeEvents, (events) => events.filter((e) => e.start.paddock_uuid === paddockId));

export const selectGrazeEventsForHerd = (
  herdId: string
): MemoizedSelector<any, Array<GrazeEvent>, DefaultProjectorFn<Array<GrazeEvent>>> =>
  createSelector(selectGrazeEvents, (events) => events.filter((e) => e.start.herd_snapshots.herd_uuid === herdId));
export const selectGrazeEventsForHerds = (
  herdIds: string[]
): MemoizedSelector<any, EventsPerHerd, DefaultProjectorFn<EventsPerHerd>> =>
  createSelector(selectGrazeEvents, (events) => {
    return Object.fromEntries(herdIds.map((herdId) => [herdId, selectGrazeEventsForHerd(herdId).projector(events)]));
  });
export const selectPlannedEventsForHerd = (
  herdId: string
): MemoizedSelector<any, Array<GrazeEvent>, DefaultProjectorFn<Array<GrazeEvent>>> =>
  createSelector(selectPlannedEvents, (events) => events.filter((e) => e.start.herd_snapshots.herd_uuid === herdId));
export const selectPlannedEventsForHerds = (
  herdIds: string[]
): MemoizedSelector<any, EventsPerHerd, DefaultProjectorFn<EventsPerHerd>> =>
  createSelector(selectPlannedEvents, (events) => {
    return Object.fromEntries(herdIds.map((herdId) => [herdId, selectGrazeEventsForHerd(herdId).projector(events)]));
  });
export const selectRanchConfig = createSelector(selectRanch, (ranch) => ranch?.config);
export const selectMaxAnimalCount = createSelector(selectRanchConfig, (config) => config?.max_animals || 10000);
export const selectRestDaysPerPaddock = createSelector(selectGrazeEvents, (events) => restDaysStatistics(events));

export const selectEarliestPossibleMovesPerHerd = createSelector(
  selectRanchState,
  (state) => state.earliestPossibleMovePerHerd
);
export const selectPaddockHistoryByIds = (
  eventIds: string[]
): MemoizedSelector<any, Array<PaddockHistory>, DefaultProjectorFn<Array<PaddockHistory>>> =>
  createSelector(selectPaddocks, (paddocks) =>
    paddocks
      .map((p) => p.history)
      .flat()
      .filter((e) => eventIds.includes(e.uuid))
  );

export type EventsPerHerd = {
  [herdId: string]: Array<GrazeEvent>;
};
export type HerdLocation = { herdId: string; paddock: Paddock };

const herdsLocation = (state: State): HerdLocation[] => {
  const events = state.historicalEvents;
  const paddocks = state.paddocks;
  const paddockByHerd = new Map<string, Paddock>();
  events.forEach((event) => {
    if (event.end) {
      paddockByHerd.set(event.start.herd_snapshots.herd_uuid, null);
    } else {
      paddockByHerd.set(
        event.start.herd_snapshots.herd_uuid,
        paddocks.find((p) => event.start.paddock_uuid === p.uuid)
      );
    }
  });
  return Array.from(paddockByHerd, ([herdId, paddock]) => ({ herdId, paddock }));
};

const restDaysStatistics = (events: GrazeEvent[]): Map<string, number> => {
  const now = Date.now();
  const result = new Map<string, number | null>();
  events.forEach(({ start, end }) => {
    if (!end) {
      result.set(start.paddock_uuid, null);
      return;
    }
    const currentDiff = (now - Date.parse(end.record_date)) / MsInDay;
    if (!result.has(start.paddock_uuid) || currentDiff < result.get(start.paddock_uuid)) {
      result.set(start.paddock_uuid, currentDiff);
    }
  });
  return result;
};
