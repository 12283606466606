<div class="cache-items visibility-hidden"><i class="fas fa-wifi-slash"></i></div>
<div class="offline-status" *ngIf="!connected">
  <div class="this-content">
    <div class="icon"><i class="fas fa-wifi-slash"></i></div>
    <div class="text">
      <h1>Warning!</h1>
      <h2>
        An internet connection is required to use the Pasturemap
        <br />
        ...and it's missing
      </h2>
      <p>
        Seems like you has been disconnected. Don't panic.
        <br />
        <br />
        <b>Do not refresh this page.</b>
        <br />
        <br />
        Just double check if you have a stable connection.
      </p>
    </div>
  </div>
</div>
<div [@routeAnimations]="prepareRoute(outlet)" [class.blurMe]="!connected">
  <mat-progress-bar mode="indeterminate" *ngIf="pendingRequest$ | async"></mat-progress-bar>
  <router-outlet #outlet="outlet"></router-outlet>
</div>
