import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { Herd, HerdHistory } from 'src/app/shared/models/herd.interface';
import { State } from './livestock.state';
import { Animal } from 'src/app/shared/models/animal.model';
import { RootState } from '../..';

const selectLivestockState = (rootState: RootState): State => rootState.ranch.livestockState;

export const selectAllHerds = createSelector(selectLivestockState, (state: State) => state.herds);
export const selectHerdsLoaded = createSelector(selectLivestockState, (state: State) => state.herdsLoaded);
export const selectLiveHerds = createSelector(selectAllHerds, (state: Herd[]) =>
  state.filter((s) => s.is_active && !s.is_terminal_herd)
);
export const selectNonTerminalHerds = createSelector(selectLivestockState, (state: State) =>
  state.herds.filter((h) => !h.is_terminal_herd)
);
export const selectTerminalHerds = createSelector(selectLivestockState, (state: State) =>
  state.herds.filter((h) => !!h.is_terminal_herd)
);
export const selectRetiredHerds = createSelector(selectAllHerds, (state: Herd[]) =>
  state.filter((s) => !s.is_active && !s.is_terminal_herd)
);
export const selectTerminalHerdByIdentification = (
  herdIdentification: string
): MemoizedSelector<any, Herd, DefaultProjectorFn<Herd>> =>
  createSelector(selectAllHerds, (herds) =>
    herds.find((h) => h.is_terminal_herd && h.identification === herdIdentification)
  );

export const selectTotalAnimalCount = createSelector(selectAllHerds, (herds) =>
  herds.filter((h) => !h.is_terminal_herd).reduce((acc, cur) => acc + cur.num_animals, 0)
);
export const selectHerd = (herdId: string): MemoizedSelector<any, Herd, DefaultProjectorFn<Herd>> =>
  createSelector(selectAllHerds, (herds) => herds.find((h) => h.uuid === herdId));
export const selectHerds = (herdIds: string[]): MemoizedSelector<any, Herd[], DefaultProjectorFn<Herd[]>> =>
  createSelector(selectAllHerds, (herds) => herdIds.map((id) => herds.find((h) => h.uuid === id)));
export const selectHerdName = (herdId: string): MemoizedSelector<Herd, string, DefaultProjectorFn<string>> =>
  createSelector(selectHerd(herdId), (herd) => herd?.identification);

export const selectHerdHistory = (
  herdId: string
): MemoizedSelector<any, Array<HerdHistory>, DefaultProjectorFn<Array<HerdHistory>>> =>
  createSelector(selectLivestockState, (state: State) => state.herdHistory[herdId]);

export const selectHerdAnimals = (
  herdId: string
): MemoizedSelector<any, Array<Animal>, DefaultProjectorFn<Array<Animal>>> =>
  createSelector(selectLivestockState, (state: State) => state.herdAnimals[herdId]);

export const selectTerminalHerdAnimals = (
  herdId: string
): MemoizedSelector<any, Array<Animal>, DefaultProjectorFn<Array<Animal>>> =>
  createSelector(selectLivestockState, (state: State) => state.herdTerminalAnimals[herdId]);
