import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first, firstValueFrom, skip } from 'rxjs';
import { SettingsUserService } from 'src/app/settings/settings-user.service';
import { Me } from 'src/app/shared/models/user.interface';
import { GetMe } from 'src/app/store/user/user.actions';
import { selectMe } from 'src/app/store/user/user.selectors';

@Component({
  selector: `pasture-create-ranch`,
  templateUrl: `./create-ranch.component.html`,
  styleUrls: [`./create-ranch.component.scss`],
})
export class CreateRanchComponent {
  public isLoading = true;
  public me: Me;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly sus: SettingsUserService
  ) {
    if (sus.ranchUUID) {
      router.navigateByUrl(`/`);
    }
    this.store
      .select(selectMe)
      .pipe(
        filter((v) => !!v),
        first()
      )
      .subscribe((me) => {
        this.me = me;
        this.isLoading = false;
      });
  }

  public async createRanch(): Promise<void> {
    this.isLoading = true;

    await firstValueFrom(this.sus.createRanch({ name: `${this.me.first_name}'s Ranch` }));

    this.store.dispatch(GetMe());
    this.store
      .select(selectMe)
      .pipe(skip(1))
      .subscribe((me) => {
        this.isLoading = false;
        if (me.ranches?.length) {
          this.router.navigateByUrl(`/`);
        }
      });
  }
}
