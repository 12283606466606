import { createReducer, on } from '@ngrx/store';
import { initialState, State } from './rainfall.state';
import * as RanchActions from './rainfall.actions';

export const rainfallReducer = createReducer<State>(
  initialState,
  on(
    RanchActions.GetRainfall,
    (state): State => ({
      ...state,
      rainfallLoading: true,
    })
  ),
  on(
    RanchActions.GetRainfallSuccess,
    (state, { rainfall }): State => ({
      ...state,
      rainfall,
      rainfallLoading: false,
    })
  ),
  on(
    RanchActions.GetRainfallFailure,
    (state): State => ({
      ...state,
      rainfallLoading: false,
    })
  ),
  on(
    RanchActions.GetRainfallStations,
    (state): State => ({
      ...state,
      rainfallStationsLoading: true,
    })
  ),
  on(
    RanchActions.GetRainfallStationsSuccess,
    (state, { rainfallStations }): State => ({
      ...state,
      rainfallStations,
      rainfallStationsLoading: false,
    })
  ),
  on(
    RanchActions.GetRainfallStationsFailure,
    (state): State => ({
      ...state,
      rainfallStationsLoading: false,
    })
  )
);
