import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { RequestTrackerService } from './request-tracker.service';

@Injectable()
export class RequestTrackerInterceptor implements HttpInterceptor {
  constructor(private requestTracker: RequestTrackerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = req.clone();
    this.requestTracker.startRequest();
    return next.handle(request).pipe(
      finalize(() => {
        this.requestTracker.endRequest();
      })
    );
  }
}
