import { InjectionToken } from '@angular/core';

export class SettingsModel {
  API_V1: string;
  API_V2: string;
  API_V3: string;
  MAPS: string;
  COOKIE_DOMAIN: string;
  ORIG_SITE: string;
  NEW_SITE: string;
  ANALYTICS_KEY: string;
  MAP_KEY: string;
  STRIPE_KEY: string;
  MAPBOX_KEY: string;
  GOOGLE_STRIPE_KEY: string;
}

export const SETTINGS_TOKEN = new InjectionToken<SettingsModel>(`SettingsConfiguration`);
