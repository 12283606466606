import { UserEffects } from './user/user.effects';
import { userReducer } from './user/user.reducer';
import { State as UserState } from './user/user.state';
import { State as SettingsState } from './settings/settings.state';
import { RanchRootState, ranchCombinedReducer, ranchEffects } from './ranch';
import { settingsReducer } from './settings/settings.reducer';

export const reducers = {
  user: userReducer,
  ranch: ranchCombinedReducer,
  settings: settingsReducer,
};

export const effects = [UserEffects, ...ranchEffects];

export type RootState = {
  user: UserState;
  ranch: RanchRootState;
  settings: SettingsState;
};
