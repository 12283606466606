import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap } from 'rxjs';

import { RanchService } from '../services/ranch.service';
import * as RanchActions from './livestock.actions';

@Injectable()
export class LivestockEffects {
  getHerds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RanchActions.GetHerds),
      mergeMap(() =>
        this.ranchService.getHerds().pipe(
          mergeMap((herds) => {
            return of(RanchActions.GetHerdsSuccess({ herds }));
          }),
          catchError(() => of(RanchActions.GetHerdsFailure()))
        )
      )
    );
  });

  getHerd$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RanchActions.GetHerd),
      mergeMap((action) =>
        this.ranchService.getHerd(action.id).pipe(
          mergeMap((herd) => {
            return of(RanchActions.GetHerdSuccess({ herd }));
          }),
          catchError(() => of(RanchActions.GetHerdFailure()))
        )
      )
    );
  });

  getHerdWithHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RanchActions.GetHerdHistory, RanchActions.GetHerdAnimalsSuccess),
      switchMap((action) =>
        this.ranchService.getHerdHistory(action.herdId).pipe(
          mergeMap((herd) => of(RanchActions.GetHerdHistorySuccess({ herd }))),
          catchError(() => of(RanchActions.GetHerdHistoryFailure()))
        )
      )
    );
  });

  getHerdAnimals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RanchActions.GetHerdAnimals),
      mergeMap((action) =>
        this.ranchService.getHerdAnimals(action.id).pipe(
          mergeMap((animals) => of(RanchActions.GetHerdAnimalsSuccess({ herdId: action.id, animals }))),
          catchError(() => of(RanchActions.GetHerdAnimalsFailure({ herdId: action.id })))
        )
      )
    );
  });

  getTerminalHerdAnimals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RanchActions.GetHerdAnimals),
      mergeMap((action) =>
        this.ranchService.getTerminalHerdAnimals(action.id).pipe(
          mergeMap((animals) => of(RanchActions.GetTerminalHerdAnimalsSuccess({ herdId: action.id, animals }))),
          catchError(() => of(RanchActions.GetTerminalHerdAnimalsFailure({ herdId: action.id })))
        )
      )
    );
  });

  constructor(private readonly actions$: Actions, private readonly ranchService: RanchService) {}
}
