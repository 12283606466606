import { ChangeDetectorRef, Component, OnInit, AfterViewInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Observable, delay, fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService } from '../shared/alert/alert.service';
import { pagesAnimations } from '../shared/animations/pages.animations';
import { RequestTrackerService } from '../shared/interceptors/request-tracker.service';

@UntilDestroy()
@Component({
  selector: `pasture-main-layout`,
  templateUrl: `./main-layout.component.html`,
  styleUrls: [`./main-layout.component.scss`],
  animations: [pagesAnimations],
})
export class MainLayoutComponent implements OnInit, AfterViewInit {
  public connected = true;
  public pendingRequest$: Observable<boolean>;

  constructor(
    private readonly alertService: AlertService,
    private readonly cdr: ChangeDetectorRef,
    requestTracker: RequestTrackerService
  ) {
    this.pendingRequest$ = requestTracker.pendingRequests$.pipe(delay(0));
  }

  ngOnInit(): void {
    const onlineEvent = fromEvent(window, `online`);
    const offlineEvent = fromEvent(window, `offline`);
    onlineEvent.pipe(untilDestroyed(this)).subscribe((e) => {
      this.connected = true;
      this.alertService.showSuccess(`You are back online`, `Great!`);
    });

    offlineEvent.pipe(untilDestroyed(this)).subscribe((e) => {
      this.connected = false;
    });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  public prepareRoute(outlet: RouterOutlet): any {
    return outlet?.activatedRouteData?.animation;
  }
}
