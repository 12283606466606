<div class="grc-banner">
  <span> Read about our carbon credits program </span>
  <a mat-flat-button color="accent" href="https://grassrootscarbon.com/ranchers/" target="_blank" class="mx-2">
    Join now!
  </a>
</div>
<div class="container-block">
  <img class="logo" src="assets/img/logo.svg" alt="PastureMap" />
  <h1>Log In</h1>
  <mat-card *ngIf="isLoading">
    <div class="padded-spinner">
      <mat-spinner diameter="90"></mat-spinner>
    </div>
  </mat-card>
  <mat-card class="padded-card" *ngIf="!isLoading">
    <form [formGroup]="loginForm" autocomplete="on" class="general-form" novalidate (ngSubmit)="login(loginForm.value)">
      <mat-form-field appearance="outline" class="full-width">
        <label class="abs-top">Email</label>
        <input type="email" required="required" matInput formControlName="email" />
        <mat-error *ngIf="hasError('email', 'required')">
          <i class="fal fa-exclamation-triangle"></i>
          Email is required
        </mat-error>
        <mat-error *ngIf="hasError('email', 'email')">
          <i class="fal fa-exclamation-triangle"></i>
          Email address contain errors
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <label class="abs-top">Password</label>
        <input required type="password" matInput formControlName="password" />
        <mat-error *ngIf="hasError('password', 'required')">
          <i class="fal fa-exclamation-triangle"></i>
          Password is required
        </mat-error>
        <mat-error *ngIf="hasError('password', 'maxlength')">
          <i class="fal fa-exclamation-triangle"></i>
          You have more than 100 characters
        </mat-error>
      </mat-form-field>
      <div class="full-width">
        <button class="big-wide-button" type="submit" mat-raised-button color="primary">Log In</button>
      </div>
    </form>
    <div class="sub-action">New user? <a class="sing-up" routerLink="/"> Sign Up</a></div>
    <div class="sub-action">
      Forgot password? <a class="reset" routerLink="/password-reset-request" routerLinkActive="active">Reset it</a>
    </div>
  </mat-card>
</div>
