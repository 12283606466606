import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import LayerGroup from 'ol/layer/Group';
import { Fill, Stroke, Style } from 'ol/style';
import { FeatureLike } from 'ol/Feature';

import { MapViewerService } from './map-viewer.service';

@Component({
  selector: `pasture-map-viewer`,
  templateUrl: `./map-viewer.component.html`,
  styleUrls: [`./map-viewer.component.scss`],
})
export class MapViewerComponent implements OnInit {
  @ViewChild(`mapElement`, { static: true }) mapElement: ElementRef;

  @Output() mapService = new EventEmitter<MapViewerService>();

  // TODO: pass real source URLs
  private readonly mapSources = [
    {
      url: `https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}`,
      attribution: `Tiles © ArcGIS`,
    },
    {
      url: `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
      attribution: `Tiles © ArcGIS`,
    },
  ];

  public currentSourceIndex = 0;

  private map: Map;
  private mapLayer: TileLayer<XYZ>;
  private geometryLayer: LayerGroup;
  private herdLayer: VectorLayer<FeatureLike>;
  private infrastructureLayer: LayerGroup;
  private labelLayer: VectorLayer<FeatureLike>;
  private temporaryLayer: VectorLayer<FeatureLike>;

  ngOnInit(): void {
    this.initializeMap();
    this.mapService.emit(new MapViewerService(this.map, this.mapElement));
  }

  public changeMapSource(mapIndex: number): void {
    this.mapLayer.getSource().setUrl(this.mapSources[mapIndex].url);
    this.currentSourceIndex = mapIndex;
  }

  private initializeMap(): void {
    this.mapLayer = new TileLayer({
      source: new XYZ({
        url: this.mapSources[0].url,
        attributions: this.mapSources[0].attribution,
      }),
    });

    const standalonePaddockLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        fill: new Fill({
          color: `#00b16a1a`,
        }),
        stroke: new Stroke({
          color: `#ffffff`,
          width: 2,
        }),
      }),
    });

    const parentPaddockLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        stroke: new Stroke({
          color: `#ffffff`,
          width: 2,
        }),
      }),
    });

    const subpaddockLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        fill: new Fill({
          color: `#00b16a1a`,
        }),
        stroke: new Stroke({
          color: `#ffffffaa`,
          width: 1,
        }),
      }),
    });

    this.herdLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const gatesLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const waterLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const balesLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const rainLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const photosLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const soilLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const othersLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const herdsPathLayer = new VectorLayer({
      source: new VectorSource(),
    });

    this.labelLayer = new VectorLayer({
      source: new VectorSource(),
      declutter: true,
    });

    this.temporaryLayer = new VectorLayer({
      source: new VectorSource(),
    });

    this.herdLayer.setZIndex(1);

    this.geometryLayer = new LayerGroup({ layers: [standalonePaddockLayer, parentPaddockLayer, subpaddockLayer] });
    this.infrastructureLayer = new LayerGroup({
      layers: [gatesLayer, waterLayer, balesLayer, rainLayer, photosLayer, soilLayer, othersLayer, herdsPathLayer],
    });

    this.map = new Map({
      target: this.mapElement.nativeElement,
      layers: [
        this.mapLayer,
        this.geometryLayer,
        this.herdLayer,
        this.infrastructureLayer,
        this.labelLayer,
        this.temporaryLayer,
      ],
      view: new View({
        center: [0, 0],
        zoom: 10,
      }),
      // interactions: new Collection(),
    });
  }
}
