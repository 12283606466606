<mat-card style="width: 500px">
  <mat-card-header>
    <mat-card-title>Content Unavailable</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>Sorry, your subscription plan does not include this feature. Please subscribe to access this content.</p>
  </mat-card-content>
  <mat-card-actions>
    <a mat-raised-button color="accent" style="color: white" [href]="subUrl">SUBSCRIBE</a>
  </mat-card-actions>
</mat-card>
