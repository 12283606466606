<div #mapElement class="map">
  <div class="tooltip"></div>
  <button mat-button #menuTrigger="matMenuTrigger" class="maps-switcher" [matMenuTriggerFor]="mapsSwitch">
    <i class="fad fa-layer-group"></i>
  </button>
</div>

<mat-menu #mapsSwitch="matMenu">
  <button mat-menu-item [disabled]="currentSourceIndex === 0" (click)="changeMapSource(0)">Hybrid</button>
  <button mat-menu-item [disabled]="currentSourceIndex === 1" (click)="changeMapSource(1)">Satelite</button>
</mat-menu>
