import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom, Observable } from 'rxjs';

import { selectRanchConfig } from 'src/app/store/ranch/ranch/ranch.selectors';

@Component({
  selector: `pasture-config-condition`,
  templateUrl: `./config-condition.component.html`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigConditionComponent implements OnInit {
  @Input() public configCondition: configProps;
  @ViewChild(`allowed`, { static: true }) allowed: TemplateRef<any>;
  @ViewChild(`prohibited`, { static: true }) prohibited: TemplateRef<any>;
  public config$: Observable<any>;

  constructor(
    private store: Store,
    private view: ViewContainerRef
  ) {}

  async ngOnInit(): Promise<void> {
    const config = await firstValueFrom(this.store.select(selectRanchConfig).pipe(filter((c) => !!c)));
    if (config[this.configCondition] === false) {
      this.view.createEmbeddedView(this.prohibited);
    } else {
      this.view.createEmbeddedView(this.allowed);
    }
    this.view.element.nativeElement.remove();
  }
}

type configProps =
  | `allow_subdivision`
  | `allow_recovery_days`
  | `allow_color_coding_pastures`
  | `allow_map_export`
  | `allow_sharing_access`
  | `allow_herd_report_export`
  | `allow_pasture_report_export`
  | `allow_aerial_imaging`
  | `allow_objects`
  | `allow_ranch_stats`
  | `allow_add_planned_move`
  | `allow_hay_the_pasture`
  | `allow_herd_charts`
  | `allow_team_members`
  | `allow_multiple_seasons`
  | `allow_stocking_calculator`
  | `allow_assign_tasks`
  | `allow_grazing_chart`
  | `allow_forage_forecast`;
