import { Directive, ElementRef, Renderer2, OnInit, HostListener } from '@angular/core';

@Directive({
  selector: `[pastureLink]`,
})
export class DecoratedLinkDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.el.nativeElement, `color`, `var(--color-primary)`);
    this.renderer.setStyle(this.el.nativeElement, `text-decoration`, `none`);
  }

  @HostListener(`mouseover`) onMouseOver(): void {
    this.renderer.setStyle(this.el.nativeElement, `text-decoration`, `underline`);
  }

  @HostListener(`mouseout`) onMouseOut(): void {
    this.renderer.setStyle(this.el.nativeElement, `text-decoration`, `none`);
  }
}
