import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Paddock } from 'src/app/shared/models/paddock.interface';
import { PaddockGeometryParser } from '../../services/paddock-geometry-parser.service';

@Component({
  selector: `pasture-paddock-selection-dialog`,
  templateUrl: `./paddock-selection-dialog.component.html`,
  styleUrls: [`./paddock-selection-dialog.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaddockSelectionDialogComponent implements OnInit {
  public paddocksToDisplay: Map<string, string[]>;
  public paddockRelations: Map<string, string[]>;
  public paddockById: Map<string, Paddock>;
  public selectedPaddock: string;
  public searchPhrase: string;

  public get paddocksWithChildren(): Array<Paddock> {
    const targetPaddockIds = [...this.paddocksToDisplay].filter(([_, v]) => v.length).map(([k, _]) => k);
    return targetPaddockIds
      .map((id) => this.paddockById.get(id))
      .sort((a, b) => a.identification.localeCompare(b.identification));
  }
  public get childlessPaddocks(): Array<Paddock> {
    const targetPaddockIds = [...this.paddocksToDisplay].filter(([_, v]) => !v.length).map(([k, _]) => k);
    return targetPaddockIds
      .map((id) => this.paddockById.get(id))
      .sort((a, b) => a.identification.localeCompare(b.identification));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { paddocks: Array<Paddock> },
    private geometryParser: PaddockGeometryParser
  ) {}

  public ngOnInit(): void {
    this.paddockById = new Map(this.data.paddocks.map((p) => [p.uuid, p]));
    this.paddockRelations = new Map<string, string[]>();
    this.data.paddocks
      .filter((p) => !p.parent_paddock_uuid)
      .forEach((parentPaddock) => {
        this.paddockRelations.set(parentPaddock.uuid, []);
      });
    this.data.paddocks
      .filter((p) => p.parent_paddock_uuid)
      .forEach((childPaddock) => {
        this.paddockRelations.get(childPaddock.parent_paddock_uuid)?.push(childPaddock.uuid);
      });
    this.paddocksToDisplay = this.paddockRelations;
  }

  public childrenOf(paddockId: string): Paddock[] {
    return this.paddocksToDisplay
      .get(paddockId)
      .map((id) => this.paddockById.get(id))
      .sort((a, b) => a.identification.localeCompare(b.identification));
  }

  public selectPaddock(paddockId: string): void {
    this.selectedPaddock = paddockId;
  }

  public search(requestedPhrase: string): void {
    const searchPhrase = requestedPhrase.toLowerCase();
    this.paddocksToDisplay = new Map(
      [...this.paddockRelations]
        .map(([parent, children]): [string, string[]] => {
          if (this.paddockById.get(parent).identification.toLowerCase().includes(searchPhrase)) {
            return [parent, children];
          }
          if (children) {
            const filteredChildrenPaddocks = children
              .map((p) => this.paddockById.get(p))
              .filter((c) => c.identification.toLowerCase().includes(searchPhrase));
            if (filteredChildrenPaddocks.length) {
              return [parent, filteredChildrenPaddocks.map((p) => p.uuid)];
            }
          }
          return [null, null];
        })
        .filter(([a, _]) => !!a)
    );
  }

  public urlForPaddock(paddock: Paddock, parent: Paddock): string {
    return this.geometryParser.paddockImageUrl(paddock, parent);
  }
}
