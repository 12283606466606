export interface Paddock {
  uuid: string;
  ranch_uuid: string;
  season_uuid: string;
  grazing_cell_uuid: string;
  parent_paddock_uuid: string;
  identification: string;
  is_active: boolean;
  activated_date: string;
  deactivated_date: string;
  species_type: string;
  percent_available_forage: number;
  area: number;
  perimeter: number;
  coordinates: { type: string; coordinates: number[][][] };
  datetime_created: string;
  datetime_modified: string;
  forage: number;
  last_move_out: string;
  history: PaddockHistory[];
  subdivision_lines_uuid: string;
  active_subpaddock_uuids: string[];
  inactive_subpaddock_uuids: string[];
  copied_from_uuid: string;
  deleted: boolean;
  available_animal_days: number;
  last_season_grazed_date: string;
  nrcs_forage_estimate: NrcsForageEstimate;
}

export interface PaddockHistory {
  uuid: string;
  paddock_uuid: string;
  user_uuid: string;
  herd_uuid: string;
  record_type: RecordType;
  location: string;
  current_grass_height: number;
  current_grass_rating: number;
  forage: number;
  estimated_grass_height_at_move_out: number;
  estimated_recovery_time: number;
  grazing_time: number;
  actual_grazing_time: number;
  percent_utilization: number;
  herd_identification: string;
  herd_species: string;
  herd_age_group: string;
  herd_num_animals: number;
  herd_total_herd_weight: number;
  herd_total_feed_per_day: number;
  photo_url: string;
  notes: string;
  record_date: string;
  datetime_created: string;
  datetime_modified: string;
  herd_snapshots: HerdSnapshot[];
  task_details: TaskDetails;
  inventory_is_haying: boolean;
  object_uuid: string;
  object_weight: number;
  object_name: string;
  deleted: boolean;
}

export interface SingleHerdPaddockHistory {
  uuid: string;
  paddock_uuid: string;
  user_uuid: string;
  // herd_uuid: string; // This prop is always null
  record_type: RecordType;
  location: string;
  current_grass_height: number;
  current_grass_rating: number;
  forage: number;
  estimated_grass_height_at_move_out: number;
  estimated_recovery_time: number;
  grazing_time: number;
  actual_grazing_time: number;
  percent_utilization: number;
  herd_identification: string;
  herd_species: string;
  herd_age_group: string;
  herd_num_animals: number;
  herd_total_herd_weight: number;
  herd_total_feed_per_day: number;
  photo_url: string;
  notes: string;
  record_date: string;
  datetime_created: string;
  datetime_modified: string;
  herd_snapshots: HerdSnapshot;
  task_details: TaskDetails;
  inventory_is_haying: boolean;
  object_uuid: string;
  object_weight: number;
  object_name: string;
  deleted: boolean;
}

export interface TaskDetails {
  uuid: string;
  creator_uuid: string;
  assignee_uuid: string;
  datetime_created: string;
  datetime_modified: string;
  datetime_completed: string;
  deleted: boolean;
}

export interface HerdSnapshot {
  uuid: string;
  paddock_history_point_uuid: string;
  herd_uuid: string;
  identification: string;
  age_group: string;
  num_animals: number;
  total_weight: number;
  total_feed_per_day: number;
  is_frozen: boolean;
  deleted: boolean;
  datetime_modified: string;
}

export interface NrcsForageEstimate {
  forage_low: number;
  forage_high: number;
  forage_regular: number;
  estimation_fraction: number;
}

export const RecordType = {
  MoveIn: `Move In`,
  MoveOut: `Move Out`,
  Inventory: `Pasture Inventory`,
  AddedHay: `Added Hay`,
  RemovedHay: `Removed Hay`,
} as const;
export type RecordType = (typeof RecordType)[keyof typeof RecordType];
