<div class="container-block">
  <img class="logo" src="assets/img/logo.svg" alt="PastureMap" />
  <h1>Reset Password</h1>
  <mat-card *ngIf="isLoading">
    <div class="padded-spinner">
      <mat-spinner diameter="90"></mat-spinner>
    </div>
  </mat-card>
  <mat-card class="padded-card" *ngIf="!isLoading">
    <form
      [formGroup]="passwordResetForm"
      autocomplete="on"
      class="general-form"
      novalidate
      (ngSubmit)="resetPassword(passwordResetForm.value)"
    >
      <mat-form-field appearance="outline" class="full-width">
        <label class="abs-top">Email</label>
        <input type="email" required="required" matInput formControlName="email" />
        <mat-error *ngIf="hasError('email', 'required')">
          <i class="fal fa-exclamation-triangle"></i>
          Email is required
        </mat-error>
        <mat-error *ngIf="hasError('email', 'email')">
          <i class="fal fa-exclamation-triangle"></i>
          Email address contain errors
        </mat-error>
      </mat-form-field>
      <div class="full-width">
        <button class="big-wide-button reset-password-button" type="submit" mat-raised-button color="primary">
          Reset Password
        </button>
      </div>
    </form>
    <div class="sub-action">Go back to <a class="login" routerLink="/login" routerLinkActive="active">Log In</a></div>
  </mat-card>
</div>
