import { Injectable } from '@angular/core';

@Injectable({
  providedIn: `root`,
})
export class HashingService {
  public async sha256(str: string): Promise<string> {
    const data: Uint8Array = new TextEncoder().encode(str);
    const hashBuffer: ArrayBuffer = await crypto.subtle.digest(`SHA-256`, data);
    return Array.from(new Uint8Array(hashBuffer))
      .map((byte: number) => byte.toString(16).padStart(2, `0`))
      .join(``);
  }
}
