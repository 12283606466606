import { Injectable } from '@angular/core';

declare const dataLayer: any;

@Injectable({
  providedIn: `root`,
})
export class EventTrackerService {
  private dataLayer: any;

  constructor() {
    try {
      this.dataLayer = dataLayer;
    } catch {
      this.dataLayer = null;
    }
  }

  public push(eventObject: object): void {
    if (this.dataLayer) {
      this.dataLayer.push(eventObject);
    }
  }
}
