import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectHerdName } from 'src/app/store/ranch/livestock/livestock.selectors';

@Pipe({
  name: `herdName`,
})
export class HerdNamePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(value: string): Observable<string> {
    return this.store.select(selectHerdName(value));
  }
}
