<div class="container-block">
  <img class="logo" src="assets/img/logo.svg" alt="PastureMap" />
  <h1>Set New Password</h1>
  <mat-card *ngIf="isLoading">
    <div class="padded-spinner">
      <mat-spinner diameter="90"></mat-spinner>
    </div>
  </mat-card>
  <mat-card class="padded-card" *ngIf="!isLoading">
    <form
      [formGroup]="passwordResetForm"
      autocomplete="on"
      class="general-form"
      novalidate
      (ngSubmit)="resetPassword(passwordResetForm.controls['passwordNew'].value)"
    >
      <mat-form-field appearance="outline" class="full-width">
        <label class="abs-top">New Password</label>
        <input
          type="password"
          required="required"
          matInput
          formControlName="passwordNew"
          [type]="showPassword ? 'text' : 'password'"
        />
        <button type="button" class="toggle-password" mat-icon-button matSuffix (click)="showPassword = !showPassword">
          <mat-icon *ngIf="showPassword">visibility_off</mat-icon>
          <mat-icon *ngIf="!showPassword">visibility</mat-icon>
        </button>
        <mat-error *ngIf="hasError('passwordNew', 'required')">
          <i class="fal fa-exclamation-triangle"></i>
          This field is required
        </mat-error>
        <mat-error *ngIf="hasError('passwordNew', 'passwordStrength')">
          <i class="fal fa-exclamation-triangle"></i>
          {{ passwordResetForm.get('passwordNew').errors['passwordStrength'] }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <label class="abs-top">Re-enter New Passsword</label>
        <input
          type="password"
          required="required"
          matInput
          formControlName="passwordConfirm"
          [type]="showPassword ? 'text' : 'password'"
        />
        <button type="button" class="toggle-password" mat-icon-button matSuffix (click)="showPassword = !showPassword">
          <mat-icon *ngIf="showPassword">visibility_off</mat-icon>
          <mat-icon *ngIf="!showPassword">visibility</mat-icon>
        </button>
        <mat-error *ngIf="hasError('passwordConfirm', 'required')">
          <i class="fal fa-exclamation-triangle"></i>
          This field is required
        </mat-error>
        <mat-error *ngIf="hasError('passwordConfirm', 'mismatch')">
          <i class="fal fa-exclamation-triangle"></i>
          Passwords don't match.
        </mat-error>
      </mat-form-field>
      <div class="full-width">
        <button class="big-wide-button reset-password-button" type="submit" mat-raised-button color="primary">
          Set Password
        </button>
      </div>
    </form>
    <div class="sub-action">
      Go back to
      <a class="login" routerLink="/login" routerLinkActive="active">Log In</a>
    </div>
  </mat-card>
</div>
