import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { SETTINGS_TOKEN } from './app/shared/settings/settings.model';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: `https://30afe8c8a62842e892b8949343dd4bea@sentry.io/65529`,
  });
}

fetch(`assets/json/settingsV.json`)
  .then((res) => res.json())
  .then((config) => {
    platformBrowserDynamic([
      {
        provide: SETTINGS_TOKEN,
        useValue: config,
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
