import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectUnits } from 'src/app/store/settings/settings.selectors';

@Component({
  selector: `pasture-input-with-unit`,
  templateUrl: `./input-with-unit.component.html`,
  styleUrls: [`./input-with-unit.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
@UntilDestroy()
export class InputWithUnitComponent implements OnInit {
  @Input() unit: string;
  @Input() min = 0;
  @Input() max = Number.MAX_VALUE;
  @Input() step = 1;
  @Input() control: FormControl<number>;
  @Input() invalid: boolean;
  @Input() disabled: boolean;
  private _value: number;
  public get value(): number {
    return this._value;
  }

  @Input() set value(v: number) {
    this._value = v;
    this.control?.setValue(v);
  }

  @Output() valueChange = new EventEmitter<number>();

  public unit$: Observable<string>;

  constructor(private readonly store: Store) {
    this.unit$ = this.store.select(selectUnits);
  }

  ngOnInit(): void {
    if (!this.control) {
      this.control = new FormControl<number>({ value: this.value, disabled: this.disabled });
    }
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((v) => {
      this._value = v;
      this.valueChange.next(v);
    });
  }
}
