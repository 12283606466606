import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { PasswordValidator } from 'src/app/shared/validators/password.validator';

@Component({
  selector: `pasture-password-reset`,
  templateUrl: `./password-reset.component.html`,
  styleUrls: [`./password-reset.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordResetComponent implements OnInit {
  public isLoading: boolean;
  public passwordResetForm: FormGroup;
  public showPassword: boolean;
  private token: string;

  constructor(
    private alertService: AlertService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.isLoading = false;
    this.token = this.route.snapshot.queryParamMap.get(`token`);
    if (!this.token) {
      this.router.navigateByUrl(`/404`);
    } else {
      this.authService.removeAuthData();
    }
  }

  ngOnInit(): void {
    this.passwordResetForm = new FormGroup(
      {
        passwordNew: new FormControl(``, [Validators.required, PasswordValidator]),
        passwordConfirm: new FormControl(``, [Validators.required]),
      },
      { validators: this.passwordMatchValidator(), updateOn: `change` }
    );
  }

  public hasError = (controlName: string, errorName: string): boolean => {
    return this.passwordResetForm.controls[controlName].hasError(errorName);
  };

  public resetPassword(password: string): void {
    if (this.passwordResetForm.valid) {
      this.isLoading = true;
      this.authService.resetPassword(password, this.token).subscribe({
        next: (a) => {
          this.router.navigate([`login`]);
          this.alertService.showSuccess(`Log in with new password`, `Success`);
        },
        error: (errorResponse: any) => {
          this.isLoading = false;
          this.alertService.showError(errorResponse.error.error ?? errorResponse.statusText, ``);
        },
      });
    }
  }

  private passwordMatchValidator(): ValidatorFn {
    return (g: FormGroup): ValidationErrors | null => {
      const passwordNewControl = g.controls[`passwordNew`];
      const passwordConfirmControl = g.controls[`passwordConfirm`];
      const currentErrors = passwordConfirmControl.errors;

      const mismatchError = passwordNewControl.value === passwordConfirmControl.value ? null : { mismatch: true };
      if (currentErrors && !mismatchError) {
        delete currentErrors[`mismatch`];
        passwordConfirmControl.setErrors(Object.keys(currentErrors).length > 0 ? currentErrors : null);
      } else if (mismatchError) {
        passwordConfirmControl.setErrors({ ...currentErrors, ...mismatchError });
      }
      return mismatchError;
    };
  }
}
