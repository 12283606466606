import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: `centsCurrency`,
})
export class CentsCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: number): string | null {
    return this.currencyPipe.transform(value / 100);
  }
}
