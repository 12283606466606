import { Store } from '@ngrx/store';
import { Observable, concat, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';

import { selectMe } from 'src/app/store/user/user.selectors';
import { SettingsService } from 'src/app/shared/settings/settings.service';
import { SettingsUserService } from 'src/app/settings/settings-user.service';
import { selectPlannedEventsForHerd, selectSeason } from 'src/app/store/ranch/ranch/ranch.selectors';
import { SEASON_TOKEN } from 'src/app/shared/constants/cookie-names.const';
import { GrazeEvent } from 'src/app/shared/models/graze-event.model';
import { MsInDay, trimSeconds } from 'src/app/shared/constants/time.constant';
import { ImageUploadService } from 'src/app/shared/services/image-upload.service';
import { SingleHerdPaddockHistory } from '../models/paddock.interface';

@Injectable()
export class GrazingEventManagementService {
  private get histoyPointUrl(): string {
    return `${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/season/${this.seasonId}/historypoint`;
  }

  private seasonId: string;
  private creatorId: string;

  constructor(
    private store: Store,
    private http: HttpClient,
    private settings: SettingsService,
    private sus: SettingsUserService,
    private cookies: CookieService,
    private imageUploader: ImageUploadService
  ) {
    this.getCurrentSeason();
    this.getUser();
  }

  public async createMove(
    herd: string,
    moveConfig: MoveConfiguration,
    mode: Mode
  ): Promise<Partial<PaddockHistoryPointDto>[]> {
    if (mode?.newPlanned) {
      return [this.createPlannedEventPayload(herd, moveConfig.targetPaddockMoveIn)];
    }
    if (mode?.isPlanned) {
      const result = await this.performPlannedMovePayload(herd, moveConfig, mode.isPlanned.originalEventId);
      return result.flat();
    }
    const result = await this.performMovePayload(herd, moveConfig);
    return result.flat();
  }

  public async updateMove(
    event: SingleHerdPaddockHistory,
    moveConfig: MoveConfiguration
  ): Promise<Partial<PaddockHistoryPointDto>[]> {
    const requests: Partial<PaddockHistoryPointDto>[] = [];
    const params = moveConfig.targetPaddockMoveIn;
    requests.push(this.editPlannedEventPayload(event.uuid, params));
    if (event.grazing_time !== params.plannedDays || Date.parse(event.record_date) !== Date.parse(params.recordDate)) {
      requests.push(
        ...(await this.updateFollowingPlannedEvents(event.herd_snapshots.herd_uuid, moveConfig, event.uuid))
      );
    }
    return requests;
  }

  public reorderPlannedEvents(plannedEvents: GrazeEvent[]): Partial<PaddockHistoryPointDto>[] {
    const firstEvent = plannedEvents.reduce(
      (acc, event) => (!acc || event.start.record_date < acc.start.record_date ? event : acc),
      null
    );
    const recordDate = plannedEvents.map((e) => e.start.record_date).sort()[0];

    return this.updatePlannedEvents(
      recordDate,
      firstEvent.start.grazing_time,
      plannedEvents,
      firstEvent.start.uuid,
      true
    );
  }

  public updatePlannedEventsOrder(events: Partial<PaddockHistoryPointDto>[]): Observable<any> {
    return this.http.patch(this.histoyPointUrl, events);
  }

  public batchHandleMoves(events: Partial<PaddockHistoryPointDto>[]): Observable<any> {
    const requests = [];
    events.forEach((event) => {
      if (event.uuid) {
        requests.push(this.http.patch(this.histoyPointUrl, [event]));
      } else {
        requests.push(this.http.post(this.histoyPointUrl, [event]));
      }
    });
    return concat(...requests);
  }

  public deletePlannedEvent(eventId: string): Observable<any> {
    return this.http.delete(`${this.histoyPointUrl}/${eventId}`);
  }

  private async getCurrentSeason(): Promise<void> {
    const season = await firstValueFrom(this.store.select(selectSeason(this.cookies.get(SEASON_TOKEN))));
    this.seasonId = season.uuid;
  }

  private async getUser(): Promise<void> {
    const me = await firstValueFrom(this.store.select(selectMe));
    this.creatorId = me.uuid;
  }

  private async performMovePayload(
    herd: string,
    config: MoveConfiguration
  ): Promise<Partial<PaddockHistoryPointDto>[]> {
    const payloads: Partial<PaddockHistoryPointDto>[] = [];
    // Move out of currently grazing paddock
    if (config.previousPaddockMoveOut) {
      payloads.push(await this.movePayload(`Move Out`, herd, config.previousPaddockMoveOut));
    }
    // Move in to selected paddock
    if (config.targetPaddockMoveIn) {
      payloads.push(await this.movePayload(`Move In`, herd, config.targetPaddockMoveIn));
    }
    // End grazing for finite events
    if (config.targetPaddockMoveOut) {
      payloads.push(await this.movePayload(`Move Out`, herd, config.targetPaddockMoveOut));
    }
    return payloads;
  }

  private async performPlannedMovePayload(
    herd: string,
    config: MoveConfiguration,
    originalEventId: string
  ): Promise<Partial<PaddockHistoryPointDto>[]> {
    const payloads: Partial<PaddockHistoryPointDto>[] = [];
    // Move out of currently grazing paddock
    if (config.previousPaddockMoveOut) {
      payloads.push(await this.movePayload(`Move Out`, herd, config.previousPaddockMoveOut));
    }
    // Move in to selected paddock
    payloads.push(await this.completePlannedEventPayload(herd, config.targetPaddockMoveIn, originalEventId));
    // End grazing for finite events
    if (config.targetPaddockMoveOut) {
      payloads.push(await this.movePayload(`Move Out`, herd, config.targetPaddockMoveOut));
    }
    // Update other planned events recordDates
    payloads.push(...(await this.updateFollowingPlannedEvents(herd, config, originalEventId)));
    return payloads;
  }

  private async movePayload(
    record_type: `Move In` | `Move Out`,
    herd: string,
    params: SingleMoveParameters
  ): Promise<Partial<PaddockHistoryPointDto>> {
    const photoBlob = params.photoBlob;
    let photo_url: string = null;
    if (photoBlob) {
      photo_url = await firstValueFrom(this.imageUploader.uploadPhoto(photoBlob));
    }
    const payload: Partial<PaddockHistoryPointDto> = {
      record_date: params.recordDate,
      grazing_time: params.plannedDays,
      forage: params.dryMatter,
      notes: params.notes,
      photo_url,
      record_type,
      paddock_uuid: params.paddockUuid,
      herd_uuids: [herd],
      internal_notes: [
        {
          client: `web`,
          network_status: `online`,
          view: `map`,
          event: record_type,
        },
      ],
    };
    return payload;
  }

  private createPlannedEventPayload(herd: string, params: SingleMoveParameters): Partial<PaddockHistoryPointDto> {
    const payload: Partial<PaddockHistoryPointDto> = {
      paddock_uuid: params.paddockUuid,
      herd_uuids: [herd],
      record_date: params.recordDate,
      grazing_time: params.plannedDays,
      record_type: `Move In`,
      notes: params.notes,
      task_details: {
        assignee_uuid: params.assigneeUuid,
        creator_uuid: this.creatorId,
      },
      internal_notes: [
        {
          client: `web`,
          network_status: `online`,
          view: `map`,
          event: `createPlannedEvent`,
        },
      ],
    };

    return payload;
  }

  private editPlannedEventPayload(eventId: string, params: SingleMoveParameters): Partial<PaddockHistoryPointDto> {
    const payload: Partial<PaddockHistoryPointDto> = {
      uuid: eventId,
      paddock_uuid: params.paddockUuid,
      record_date: params.recordDate,
      grazing_time: params.plannedDays,
      record_type: `Move In`,
      notes: params.notes,
      task_details: {
        assignee_uuid: params.assigneeUuid,
        creator_uuid: this.creatorId,
      },
      internal_notes: [
        {
          client: `web`,
          network_status: `online`,
          view: `map`,
          event: `editPlannedEvent`,
        },
      ],
    };
    return payload;
  }

  private async completePlannedEventPayload(
    herd: string,
    params: SingleMoveParameters,
    originalEventId: string
  ): Promise<Partial<PaddockHistoryPointDto>> {
    const photoBlob = params.photoBlob;
    let photo_url: string = null;
    if (photoBlob) {
      photo_url = await firstValueFrom(this.imageUploader.uploadPhoto(photoBlob));
    }

    const payload: Partial<PaddockHistoryPointDto> = {
      uuid: originalEventId,
      record_date: params.recordDate,
      grazing_time: params.plannedDays,
      forage: params.dryMatter,
      notes: params.notes,
      photo_url,
      record_type: `Move In`,
      paddock_uuid: params.paddockUuid,
      task_details: { datetime_completed: trimSeconds(new Date()).toISOString() } as any,
      herd_uuids: [herd],
      internal_notes: [
        {
          client: `web`,
          network_status: `online`,
          view: `map`,
          event: `executePlannedMove`,
        },
      ],
    };
    return payload;
  }

  private async updateFollowingPlannedEvents(
    herd: string,
    config: MoveConfiguration,
    originalEventId: string
  ): Promise<Partial<PaddockHistoryPointDto>[]> {
    const plannedEvents = await firstValueFrom(this.store.select(selectPlannedEventsForHerd(herd)));

    const recordDate = config.targetPaddockMoveIn.recordDate;
    let grazingDays: number = config.targetPaddockMoveIn.plannedDays;
    if (config.targetPaddockMoveOut) {
      // actual grazing days
      grazingDays =
        (Date.parse(config.targetPaddockMoveOut.recordDate) - Date.parse(config.targetPaddockMoveIn.recordDate)) /
        MsInDay;
    } else {
      grazingDays = config.targetPaddockMoveIn.plannedDays;
    }
    const followingEvents = plannedEvents.filter((e) => Date.parse(e.start.record_date) > Date.parse(recordDate));
    return followingEvents.length > 0
      ? this.updatePlannedEvents(recordDate, grazingDays, followingEvents, originalEventId)
      : [];
  }

  private updatePlannedEvents(
    recordDate: string,
    grazingDays: number,
    plannedGrazeEvents: GrazeEvent[],
    originalEventId: string,
    includeOriginalEvent = false
  ): Partial<PaddockHistoryPointDto>[] {
    const plannedEvents = plannedGrazeEvents.map((e) => e.start);
    let laterEvents = plannedEvents;
    if (!includeOriginalEvent) {
      laterEvents = plannedEvents.filter((e) => e.uuid !== originalEventId);
    }

    const payload = [];
    let previousRecordFinishDate = includeOriginalEvent
      ? Date.parse(recordDate)
      : Date.parse(recordDate) + grazingDays * MsInDay;
    laterEvents.forEach((event) => {
      const newRecordDate = previousRecordFinishDate;

      payload.push({
        uuid: event.uuid,
        record_date: new Date(newRecordDate).toISOString(),
        task_details: {
          uuid: event.task_details.uuid,
          creator_uuid: event.task_details.creator_uuid,
          assignee_uuid: event.task_details.assignee_uuid,
          datetime_created: event.task_details.datetime_created,
          datetime_modified: trimSeconds(new Date()).toISOString(),
          datetime_completed: event.task_details.datetime_completed,
          deleted: event.task_details.deleted,
        },
        internal_notes: [
          {
            client: `web`,
            network_status: `online`,
            view: `map`,
            event: `adjustPlannedRecordDates`,
          },
        ],
      });
      previousRecordFinishDate = newRecordDate + event.grazing_time * MsInDay;
    });
    return payload;
  }
}

type InternalNoteDto = {
  client: `web`;
  network_status: `online`;
  view: string;
  event: string;
  related_object_uuid?: string;
};

type FullTaskDetailsDto = {
  uuid: string;
  creator_uuid: string;
  assignee_uuid: string;
  datetime_created: string;
  datetime_modified: string;
  datetime_completed: string;
  deleted: boolean;
};

type TaskDetailsDto = {
  assignee_uuid: string;
  creator_uuid: string;
  datetime_completed?: string;
};

type PaddockHistoryPointDto = {
  uuid?: string;
  paddock_uuid: string;
  herd_uuids: string[];
  record_date: string;
  grazing_time: number;
  forage?: number;
  photo_url?: string;
  record_type: `Move In` | `Move Out`;
  task_details: TaskDetailsDto | FullTaskDetailsDto;
  notes: string;
  internal_notes: InternalNoteDto[];
};

export type SingleMoveParameters = {
  paddockUuid: string;
  recordDate: string;
  plannedDays?: number;
  notes?: string;
  assigneeUuid?: string;
  dryMatter?: number;
  photoBlob?: File;
};

export type MoveConfiguration = {
  previousPaddockMoveOut?: SingleMoveParameters;
  targetPaddockMoveIn?: SingleMoveParameters;
  targetPaddockMoveOut?: SingleMoveParameters;
};

type Mode = { isPlanned: { originalEventId: string }; newPlanned: { firstPlanned: boolean } };
