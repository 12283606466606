<mat-list cdkDropList (cdkDropListDropped)="drop($event)">
  <mat-list-item cdkDrag *ngFor="let config of columnConfiguration" (click)="$event.stopPropagation()" disableRipple>
    <div class="menu-row">
      <mat-icon>drag_indicator</mat-icon>
      <span class="col-name">{{ visibleName(config.col) }}</span>
      <mat-checkbox [(ngModel)]="config.visible" (ngModelChange)="emitEvents()"></mat-checkbox>
    </div>
  </mat-list-item>
</mat-list>
<div class="restet-button-container">
  <button mat-flat-button (click)="reset()" color="warn">RESET</button>
</div>
