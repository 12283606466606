import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Injectable()
export class ImageUploadService {
  constructor(private http: HttpClient) {}

  public uploadPhoto(file: File): Observable<string> {
    const headers = new HttpHeaders().set(`content-type`, `image/*`);
    const params = new HttpParams().set(`key`, `AY9pZOc6sR7CXvAGGJAFez`).set(`mimetype`, `image/webp`);

    return this.http
      .post(`https://www.filepicker.io/api/store/s3`, file, {
        headers,
        params,
      })
      .pipe(map((response: any) => response.url));
  }
}
