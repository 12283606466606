import { combineReducers } from '@ngrx/store';
import { livestockReducer } from './livestock/livestock.reducer';
import { rainfallReducer } from './rainfall/rainfall.reducer';
import { ranchReducer } from './ranch/ranch.reducer';

import { State as RanchState } from './ranch/ranch.state';
import { State as RainfallState } from './rainfall/rainfall.state';
import { State as LivestockState } from './livestock/livestock.state';
import { RanchEffects } from './ranch/ranch.effects';
import { LivestockEffects } from './livestock/livestock.effects';
import { RainfallEffects } from './rainfall/rainfall.effects';

export * as RanchActions from './ranch/ranch.actions';
export * as LivestockActions from './livestock/livestock.actions';
export * as RainfallActions from './rainfall/rainfall.actions';

export const ranchCombinedReducer = combineReducers({
  ranchState: ranchReducer,
  rainfallState: rainfallReducer,
  livestockState: livestockReducer,
});

export type RanchRootState = {
  ranchState: RanchState;
  rainfallState: RainfallState;
  livestockState: LivestockState;
};

export const ranchEffects = [RanchEffects, LivestockEffects, RainfallEffects];
