import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {
  static email(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value.length === 0) {
      return null;
    }
    return RegExp(/^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,9}$/).test(control.value) ? null : { email: true };
  }
}
