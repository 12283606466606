import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectPaddock } from 'src/app/store/ranch/ranch/ranch.selectors';
import { Paddock } from '../models/paddock.interface';

@Pipe({
  name: `paddock`,
})
export class PaddockPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(value: string): Observable<Paddock> {
    return this.store.select(selectPaddock(value));
  }
}
