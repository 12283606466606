import { createAction, props } from '@ngrx/store';
import { UserSubscription } from 'src/app/shared/models/user-subscription.interface';
import { TeamMember, Me, RanchSettings } from 'src/app/shared/models/user.interface';

export enum Types {
  GetMe = `[User] Get me`,
  GetMeWithDefaults = `[User] Get me and set default ranch/season`,
  GetMeSuccess = `[User] Get me success`,
  GetMeFailure = `[User] Get me failure`,

  GetSub = `[User] Get sub`,
  GetSubSuccess = `[User] Get sub success`,
  GetSubFailure = `[User] Get sub failure`,

  GetTeam = `[User] Get team`,
  GetTeamSuccess = `[User] Get team success`,
  GetTeamFailure = `[User] Get team failure`,

  SetRanchSettings = `[User] Set ranch settings`,
  SetRanchSettingsSuccess = `[User] Set ranch settings success`,
  SetRanchSettingsFailure = `[User] Set ranch settings failure`,
}

export const GetMe = createAction(Types.GetMe);
export const GetMeWithDefaults = createAction(Types.GetMeWithDefaults);
export const GetMeSuccess = createAction(Types.GetMeSuccess, props<{ me: Me }>());
export const GetMeFailure = createAction(Types.GetMeFailure);

export const GetSub = createAction(Types.GetSub);
export const GetSubSuccess = createAction(Types.GetSubSuccess, props<{ sub: UserSubscription }>());
export const GetSubFailure = createAction(Types.GetSubFailure);

export const GetTeam = createAction(Types.GetTeam);
export const GetTeamSuccess = createAction(Types.GetTeamSuccess, props<{ team: Array<TeamMember> }>());
export const GetTeamFailure = createAction(Types.GetTeamFailure);

export const SetRanchSettings = createAction(Types.SetRanchSettings, props<{ settings: RanchSettings }>());
export const SetRanchSettingsSuccess = createAction(
  Types.SetRanchSettingsSuccess,
  props<{ settings: RanchSettings }>()
);
export const SetRanchSettingsFailure = createAction(Types.SetRanchSettingsFailure);
