<div class="container-block">
  <img class="logo" src="assets/img/logo.svg" alt="PastureMap" />
  <h1>Masquerade</h1>
  <mat-card *ngIf="isLoading">
    <div class="padded-spinner">
      <mat-spinner diameter="90"></mat-spinner>
    </div>
  </mat-card>
  <mat-card class="padded-card" *ngIf="!isLoading">
    <form
      [formGroup]="loginForm"
      autocomplete="on"
      class="general-form"
      novalidate
      (ngSubmit)="masquerade(loginForm.value)"
    >
      <mat-form-field appearance="outline" class="full-width">
        <label class="abs-top">Admin Email</label>
        <input type="email" required="required" matInput formControlName="admin_email" />
        <mat-error *ngIf="hasError('admin_email', 'required')">
          <i class="fal fa-exclamation-triangle"></i>
          Email is required
        </mat-error>
        <mat-error *ngIf="hasError('admin_email', 'email')">
          <i class="fal fa-exclamation-triangle"></i>
          Email address contain errors
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <label class="abs-top">User Email</label>
        <input type="email" required="required" matInput formControlName="user_email" />
        <mat-error *ngIf="hasError('user_email', 'required')">
          <i class="fal fa-exclamation-triangle"></i>
          Email is required
        </mat-error>
        <mat-error *ngIf="hasError('user_email', 'email')">
          <i class="fal fa-exclamation-triangle"></i>
          Email address contain errors
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <label class="abs-top">Admin Password</label>
        <input required type="password" matInput formControlName="admin_password" />
        <mat-error *ngIf="hasError('admin_password', 'required')">
          <i class="fal fa-exclamation-triangle"></i>
          Password is required
        </mat-error>
        <mat-error *ngIf="hasError('admin_password', 'maxlength')">
          <i class="fal fa-exclamation-triangle"></i>
          You have more than 100 characters
        </mat-error>
      </mat-form-field>
      <div class="full-width">
        <button class="big-wide-button" type="submit" mat-raised-button color="primary">Log In</button>
      </div>
    </form>
  </mat-card>
</div>
