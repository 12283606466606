import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, combineLatest, combineLatestWith, filter, map, mergeMap, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { RanchService } from '../services/ranch.service';
import * as RainfallActions from './rainfall.actions';
import * as RanchActions from '../ranch/ranch.actions';
import { selectRainfallStations } from './rainfall.selectors';
import { selectSeason } from '../ranch/ranch.selectors';
import { SEASON_TOKEN } from 'src/app/shared/constants/cookie-names.const';

@Injectable()
export class RainfallEffects {
  getRainfall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RainfallActions.GetRainfall),
      mergeMap(() =>
        this.ranchService.getRainfall().pipe(
          map((res) => res.filter((v) => v.value > 0)),
          mergeMap((rainfall) => of(RainfallActions.GetRainfallSuccess({ rainfall }))),
          catchError(() => of(RainfallActions.GetRainfallFailure()))
        )
      )
    );
  });

  getRainfallStations$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(RainfallActions.GetRainfallStations)),
      this.store.select(selectSeason(this.cookieService.get(SEASON_TOKEN))).pipe(filter((s) => !!s)),
    ]).pipe(
      mergeMap(([, currentSeason]) =>
        this.ranchService.getRainfallStations().pipe(
          map((res) => res.filter((station) => station.DATA_END_DATE > new Date(currentSeason.start_date).valueOf())),
          mergeMap((rainfallStations) => of(RainfallActions.GetRainfallStationsSuccess({ rainfallStations }))),
          catchError(() => of(RainfallActions.GetRainfallStationsFailure()))
        )
      )
    );
  });

  changeRainfallStartDate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RainfallActions.ChangeRainfallStartDate),
      mergeMap((action) =>
        this.ranchService.changeRainfallStartDate(action.startDate).pipe(
          mergeMap((ranch) => of(RanchActions.GetRanchSuccess({ ranch }))),
          catchError(() => of(RanchActions.GetRanchFailure()))
        )
      )
    );
  });

  changeRainfallStation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RainfallActions.ChangeRainfallStation),
      combineLatestWith(this.store.select(selectRainfallStations)),
      mergeMap(([action, stations]) =>
        this.ranchService
          .changeRainfallStation(action.id, stations.find((s) => s.STATION_ID === action.id).STATION_NAME)
          .pipe(
            map((res) => res.filter((v) => v.value > 0)),
            mergeMap((rainfall) => of(RainfallActions.GetRainfallSuccess({ rainfall }))),
            catchError(() => of(RainfallActions.GetRainfallFailure()))
          )
      )
    );
  });

  createRainfallEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RainfallActions.CreateRainfallEvent),
      mergeMap((action) =>
        this.ranchService.createRainfallEvent(action.date, action.value).pipe(
          mergeMap(() => of(RainfallActions.GetRainfall())),
          catchError(() => of(RainfallActions.GetRainfallFailure()))
        )
      )
    );
  });

  uploadRainfallCsv$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RainfallActions.UploadRainfallCsv),
      mergeMap((action) =>
        this.ranchService.uploadRainfallCsv(action.file).pipe(
          mergeMap((rainfall) => of(RainfallActions.GetRainfallSuccess({ rainfall }))),
          catchError(() => of(RainfallActions.GetRainfallFailure()))
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly ranchService: RanchService,
    private readonly store: Store,
    private readonly cookieService: CookieService
  ) {}
}
