import { createAction, props } from '@ngrx/store';
import { RainfallEvent } from 'src/app/shared/models/rainfall-event.interface';
import { RainfallStation } from 'src/app/shared/models/rainfall-station.interface';

export enum Types {
  GetRainfall = `[Rainfall] Get rainfall`,
  GetRainfallSuccess = `[Rainfall] Get rainfall success`,
  GetRainfallFailure = `[Rainfall] Get rainfall failure`,

  GetRainfallStations = `[Rainfall] Get rainfall stations`,
  GetRainfallStationsSuccess = `[Rainfall] Get rainfall stations success`,
  GetRainfallStationsFailure = `[Rainfall] Get rainfall stations failure`,

  CreateRainfallEvent = `[Rainfall] Create rainfall event`,
  ChangeRainfallStartDate = `[Rainfall] Change rainfall start date`,
  ChangeRainfallStation = `[Rainfall] Change rainfall station`,

  UploadRainfallCsv = `[Rainfall] Upload rainfall csv`,
}

export const GetRainfall = createAction(Types.GetRainfall);
export const GetRainfallSuccess = createAction(Types.GetRainfallSuccess, props<{ rainfall: Array<RainfallEvent> }>());
export const GetRainfallFailure = createAction(Types.GetRainfallFailure);

export const GetRainfallStations = createAction(Types.GetRainfallStations);
export const GetRainfallStationsSuccess = createAction(
  Types.GetRainfallStationsSuccess,
  props<{ rainfallStations: Array<RainfallStation> }>()
);
export const GetRainfallStationsFailure = createAction(Types.GetRainfallStationsFailure);

export const CreateRainfallEvent = createAction(Types.CreateRainfallEvent, props<{ date: string; value: number }>());
export const ChangeRainfallStartDate = createAction(Types.ChangeRainfallStartDate, props<{ startDate: string }>());
export const ChangeRainfallStation = createAction(Types.ChangeRainfallStation, props<{ id: string }>());
export const UploadRainfallCsv = createAction(Types.UploadRainfallCsv, props<{ file: any }>());
