import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../shared/alert/alert.service';
import { AuthService } from '../../shared/auth/auth.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordValidator } from '../../shared/validators/password.validator';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../shared/settings/settings.service';
import { CustomValidators } from 'src/app/shared/form-validators/custom-validators.class';

@Component({
  selector: `pasture-join`,
  templateUrl: `./join.component.html`,
  styleUrls: [`./join.component.scss`],
})
export class JoinComponent implements OnInit {
  public isLoading: boolean;
  public showPassword: boolean;
  public joinForm: FormGroup;
  public ranchName: string;
  private token: string;
  private dataA: any;
  private dataB: any;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private readonly settings: SettingsService,
    private router: Router
  ) {
    this.isLoading = false;
    this.showPassword = false;
    if (this.authService.isLoggedIn()) {
      this.router.navigate([`/onboarding/step-1`]);
    }
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.invitationToken;
    if (!this.token) {
      window.location.href = `/`;
    } else {
      this.decodeToken(this.token);
      this.joinForm = new FormGroup({
        fullName: new FormControl(``, [Validators.required, Validators.maxLength(100)]),
        phone: new FormControl(``, [Validators.required, Validators.maxLength(100)]),
        role: new FormControl(),
        email: new FormControl(``, [Validators.required, CustomValidators.email]),
        password: new FormControl(``, [Validators.required, PasswordValidator]),
      });
      this.joinForm.patchValue({
        email: this.dataB.email,
        role: this.dataB.role.charAt(0).toUpperCase() + this.dataB.role.slice(1),
      });
    }
  }

  public signIn(): void {
    if (this.joinForm.valid) {
      const formValues = this.joinForm.getRawValue();
      const fullName = this.joinForm.get(`fullName`).value;
      const user = {
        email: formValues.email,
        first_name: fullName.split(` `)[0],
        full_name: formValues.fullName,
        last_name: fullName.split(` `)[1] ? fullName.split(` `)[1] : fullName,
        password: formValues.password,
        phone_number: formValues.phone,
        invitation_token: this.token,
      };
      this.isLoading = true;
      this.authService.joinAndLogin(user).subscribe({
        next: () => {
          window.location.href = this.settings.settings.ORIG_SITE;
        },
        error: (errResponse: any) => {
          this.isLoading = false;
          if (typeof errResponse.error.details !== `object`) {
            this.alertService.showError(errResponse.error.details, ``);
          } else {
            for (const [key, value] of Object.entries(errResponse.error.details)) {
              this.alertService.showError(value, key);
            }
          }
        },
      });
    }
  }

  public hasError = (controlName: string, errorName: string): boolean => {
    return this.joinForm.controls[controlName].hasError(errorName);
  };

  public checkTokenDate(): boolean {
    if (typeof this.dataB.exp === `undefined`) {
      return false;
    }
    const d = new Date(0);
    const date = d.setUTCSeconds(this.dataB.exp);
    return !(date.valueOf() > new Date().valueOf() + 1000);
  }

  private decodeToken(token: string): void {
    const parts = token.split(`.`);
    if (parts.length !== 3) {
      throw new Error(`JWT must have 3 parts`);
    }
    this.dataA = JSON.parse(atob(parts[0]));
    this.dataB = JSON.parse(atob(parts[1]));
    this.ranchName = this.dataB.ranch_name;
  }
}
