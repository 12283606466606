<div class="dialog-container">
  <h2 mat-dialog-title>Select Paddock</h2>
  <mat-dialog-content class="main-content">
    <mat-form-field appearance="outline" class="search-box">
      <mat-icon matPrefix>search</mat-icon>
      <input #searchInput matInput [ngModel]="searchPhrase" (ngModelChange)="searchPhrase = $event; search($event)" />
      <button *ngIf="searchInput.value" matSuffix mat-icon-button (click)="searchPhrase = ''; search('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="paddock-with-children" *ngFor="let parent of paddocksWithChildren">
      <h2>{{ parent.identification }}</h2>
      <div class="children">
        <div class="childless-paddocks" *ngFor="let paddock of childrenOf(parent.uuid)">
          <ng-container *ngTemplateOutlet="tile; context: { paddock, parent }"></ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="childlessPaddocks.length">
      <h2>Standalone paddocks:</h2>
      <div class="children">
        <div class="childless-paddocks" *ngFor="let paddock of childlessPaddocks">
          <ng-container *ngTemplateOutlet="tile; context: { paddock }"></ng-container>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button color="primary" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!selectedPaddock" [matDialogClose]="selectedPaddock">
      Select
    </button>
  </mat-dialog-actions>
</div>

<ng-template #tile let-paddock="paddock" let-parent="parent">
  <div class="paddock" [class.selected]="paddock.uuid === selectedPaddock" (click)="selectPaddock(paddock.uuid)">
    <img [src]="urlForPaddock(paddock, parent)" />
    <span class="name">{{ paddock.identification }}</span>
  </div>
</ng-template>
