import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SettingsService } from '../../settings/settings.service';

@Component({
  selector: `pasture-generic-subscribe-card`,
  templateUrl: `./generic-subscribe-card.component.html`,
  styleUrls: [`./generic-subscribe-card.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericSubscribeCardComponent {
  public get subUrl(): string {
    return `${this.settings.settings.NEW_SITE}subscribe`;
  }

  constructor(private readonly settings: SettingsService) {}
}
