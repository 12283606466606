import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, lastValueFrom, of } from 'rxjs';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { SettingsService } from 'src/app/shared/settings/settings.service';

@Component({
  template: ``,
})
export class CancelDeleteComponent implements OnInit {
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly settings: SettingsService,
    private readonly alertService: AlertService
  ) {}

  async ngOnInit(): Promise<void> {
    const token = this.route.snapshot.queryParamMap.get(`token`);
    if (token) {
      const response = await this.sendRequest(token);
      if (response) {
        this.alertService.showSuccess(`Deletion of your account was suspended successfully!`, `Sucess`);
      }
    }
    this.router.navigateByUrl(`/login`);
  }

  private sendRequest(token: string): Promise<any> {
    return lastValueFrom(
      this.http
        .post(`${this.settings.settings.API_V1}/user/cancel_delete`, { token: token })
        .pipe(catchError(() => of(null)))
    );
  }
}
