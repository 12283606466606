import {
  Component,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TooltipDirective } from './tooltip.directive';
import { Placement } from '@popperjs/core';

@Directive({
  selector: `[pastureSubscribeTooltip]`,
})
export class SubscribeTooltipDirective extends TooltipDirective implements OnInit, OnDestroy {
  @Input() placement: Placement = `top`;

  constructor(el: ElementRef, viewContainer: ViewContainerRef) {
    super(el, viewContainer);
    this.tooltipColor = `#9993`;
    this.offset = 100;
  }

  ngOnInit(): void {
    this.config = { placement: this.placement };
  }

  enter(): void {
    const templateComponentRef = this.viewContainer.createComponent(TemplateComponent);
    templateComponentRef.changeDetectorRef.detectChanges();
    this.pastureTooltip = templateComponentRef.instance.template;
    templateComponentRef.destroy();
    super.enter();
  }
}

@Component({
  template: `
    <ng-template #template>
      <pasture-generic-subscribe-card></pasture-generic-subscribe-card>
    </ng-template>
  `,
})
export class TemplateComponent {
  @ViewChild(`template`) public template: TemplateRef<any>;
}
