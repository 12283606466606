import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: `[pastureHighlight]`,
})
export class HighlightDirective implements OnChanges {
  @Input() public pastureHighlight: boolean;

  constructor(private readonly el: ElementRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.pastureHighlight) {
      this.el.nativeElement.style.backgroundColor = this.pastureHighlight ? `#0000001f` : ``;
    }
  }
}
