import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';

@Component({
  selector: `pasture-browsercheck`,
  templateUrl: `./browsercheck.component.html`,
  styleUrls: [`./browsercheck.component.scss`],
})
export class BrowsercheckComponent {
  constructor(private deviceService: DeviceDetectorService, private router: Router) {
    if (this.deviceService.browser !== `MS-Edge` && this.deviceService.browser !== `IE`) {
      this.router.navigate([`/`]);
    }
  }
}
