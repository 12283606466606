import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { SettingsService } from '../shared/settings/settings.service';
import { LoginModel } from '../pages/login/login.model';
import { UserModel } from '../shared/auth/auth.model';
import { Me, User, Admin } from '../shared/models/user.interface';
import { Season } from '../shared/models/season.interface';
import { GetMe } from '../store/user/user.actions';
import { SetUnits } from '../store/settings/settings.actions';
import { RANCH_TOKEN, SEASON_TOKEN, ADMIN_TOKEN } from '../shared/constants/cookie-names.const';
import { Ranch } from '../shared/models/ranch.interface';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: `root`,
})
export class SettingsUserService {
  public seasonUUID: string;
  public ranchUUID: string;
  private ranchRole: string;

  constructor(
    private readonly settings: SettingsService,
    private cookieService: CookieService,
    private http: HttpClient,
    private readonly store: Store
  ) {
    const seasonFromStorage = this.getSeasonIdFromStorage();
    if (!seasonFromStorage) {
      this.cookieService.delete(SEASON_TOKEN, `/`);
    } else {
      this.seasonUUID = seasonFromStorage;
    }
  }

  public getUnits(): string {
    const units = localStorage.getItem(`units`);
    if (units) {
      return units;
    }
    return `imperial`;
  }

  public setMe(user: { email: string; first_name: string; last_name: string; phone_number: string }): Observable<any> {
    return this.http.patch(`${this.settings.settings.API_V1}/user/me`, user).pipe(
      map((response: any) => {
        this.store.dispatch(GetMe());
        return response;
      })
    );
  }

  public setUnits(data): Observable<any> {
    return this.http.patch(`${this.settings.settings.API_V1}/user/me`, { pref_imperial_units: data }).pipe(
      map((response: any) => {
        if (response.pref_imperial_units === true) {
          this.store.dispatch(SetUnits({ units: `imperial` }));
        } else {
          this.store.dispatch(SetUnits({ units: `metric` }));
        }
        this.store.dispatch(GetMe());
        return response;
      })
    );
  }

  public login(user: { email: string; password: string }): Observable<UserModel> {
    return this.http.post<LoginModel>(`${this.settings.settings.API_V1}/user/login`, user).pipe(
      map((responseUser: any) => {
        return responseUser;
      })
    );
  }

  public changePassword(data: { password: string }): Observable<any> {
    return this.http.patch<any>(`${this.settings.settings.API_V1}/user/me`, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public getCurrentRanch(): Observable<Ranch> {
    return this.http.get<Ranch>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}?units=${this.getUnits()}`);
  }

  public setCurrentRanchData(data): Observable<Ranch> {
    return this.http.patch<Ranch>(
      `${this.settings.settings.API_V1}/ranch/${this.ranchUUID}?units=${this.getUnits()}`,
      data
    );
  }

  public getSeasons(): Observable<Season[]> {
    return this.ranchUUID
      ? this.http.get<Season[]>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/season`).pipe(
          map((seasons) =>
            seasons.sort((a, b) => {
              const dateA = a.end_date ? new Date(a.end_date).getTime() : new Date().getTime();
              const dateB = b.end_date ? new Date(b.end_date).getTime() : new Date().getTime();
              return dateB - dateA;
            })
          )
        )
      : of([]);
  }

  public setSeasonData(seasonUUID: string, data: any): Observable<any> {
    return this.http.patch<any>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/season/${seasonUUID}`, data);
  }

  public getPaddocks(seasonUUID: string): Observable<any> {
    return this.http.get<any>(
      `${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/season/${seasonUUID}/paddock?units=imperial`
    );
  }

  public getUsers(): Observable<Array<User>> {
    return this.http.get<Array<User>>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/user`);
  }

  public getInvitations(): Observable<any> {
    return this.http.get<any>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/invitation`);
  }

  public deleteUser(userUUID: string): Observable<any> {
    return this.http.delete<any>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/user/${userUUID}`);
  }

  public deleteInvitation(invitationUUID: string): Observable<any> {
    return this.http.delete<any>(
      `${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/invitation/${invitationUUID}`
    );
  }

  public sendInvitation(data: { email: string; role: string }): Observable<any> {
    return this.http.post<any>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/invitation`, data);
  }

  public getSubscriptionStatus(): Observable<any> {
    return this.http.get<any>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/payment`);
  }

  public getInvoicesList(): Observable<any> {
    return this.http.get(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/subscription/invoice`);
  }

  public getUpcomingPaymentDetails(): Observable<any> {
    return this.http.get<any>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/payment/upcoming`);
  }

  public setNewSeason(data): Observable<any> {
    return this.http.post<any>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/season`, data);
  }

  public changeSeason(seasonUUID: string): Observable<any> {
    return this.http.get<any>(`${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/season/${seasonUUID}`).pipe(
      tap((season) => {
        this.setSeasonUUID(season.uuid);
      })
    );
  }

  public exportPastures(ranchId?: string): Observable<any> {
    return this.http.get(`${this.settings.settings.API_V1}/ranch/${ranchId ?? this.ranchUUID}/kml`, {
      responseType: `blob`,
    });
  }

  public exportGrazingReport(seasonUUID: string, data): Observable<any> {
    return this.http.post<any>(
      `${this.settings.settings.API_V1}/ranch/${
        this.ranchUUID
      }/season/${seasonUUID}/paddock/export_excel?units=${this.getUnits()}`,
      data
    );
  }

  public getCurrentObjects(seasonUUID: string): Observable<any> {
    return this.http.get<any>(
      `${this.settings.settings.API_V1}/ranch/${this.ranchUUID}/season/${seasonUUID}/object?units=${this.getUnits()}`
    );
  }

  public getCurrentSeason(ranchUUID?: string): Observable<any> {
    return this.http.get<any>(`${this.settings.settings.API_V1}/ranch/${ranchUUID ?? this.ranchUUID}/season`);
  }

  public exportShapeFile(seasonUUID: string): Observable<any> {
    return this.http.post<any>(
      `${this.settings.settings.API_V1}/ranch/${
        this.ranchUUID
      }/season/${seasonUUID}/paddock/export_shapefile?units=${this.getUnits()}`,
      ``
    );
  }

  public exportSelected(seasonUUID: string, data): Observable<any> {
    return this.http.post<any>(
      `${this.settings.settings.API_V1}/ranch/${
        this.ranchUUID
      }/season/${seasonUUID}/paddock/export?units=${this.getUnits()}`,
      data
    );
  }

  public createRanch(user): Observable<any> {
    return this.http.post<any>(`${this.settings.settings.API_V1}/ranch`, user);
  }

  public getRanchUUID(): string {
    return this.ranchUUID ?? this.cookieService.get(RANCH_TOKEN);
  }

  public setRanchUUID(ranchUUID: string): void {
    this.cookieService.set(RANCH_TOKEN, ranchUUID, 600000, `/`, this.settings.settings.COOKIE_DOMAIN);
    this.ranchUUID = ranchUUID;
  }

  public checkRanchUUID(ranchUUID: string, prioritizeNew = false): string {
    const selectedRanch = this.cookieService.get(RANCH_TOKEN);
    if (!selectedRanch || selectedRanch === `undefined` || !this.meRanchIds().includes(selectedRanch)) {
      this.cookieService.set(RANCH_TOKEN, ranchUUID, 600000, `/`, this.settings.settings.COOKIE_DOMAIN);
      return ranchUUID;
    }
    if (selectedRanch !== ranchUUID) {
      return prioritizeNew ? ranchUUID : selectedRanch;
    }
    return ranchUUID;
  }

  public setRanchRole(role: string): void {
    this.ranchRole = role;
  }

  public getRanchRole(): string {
    return this.ranchRole;
  }

  public setSeasonUUID(seasonUUID: string): void {
    this.cookieService.set(SEASON_TOKEN, seasonUUID, 600000, `/`, this.settings.settings.COOKIE_DOMAIN);
    this.seasonUUID = seasonUUID;
  }

  public getSeasonFromStorage(): any {
    const seasonInLocalStorage = localStorage.getItem(`currentSeason`);
    return JSON.parse(seasonInLocalStorage);
  }

  public getSeasonIdFromStorage(): string {
    return this.cookieService.get(SEASON_TOKEN);
  }

  public getAdmin(): Admin {
    const masquerade: string = this.cookieService.get(ADMIN_TOKEN);
    return masquerade ? <Admin>jwtDecode(masquerade) : null;
  }

  public removeAccountData(): Observable<void> {
    return this.http.delete<any>(`${this.settings.settings.API_V1}/user/me`);
  }

  private meRanchIds(): Array<string> {
    const me: Me = JSON.parse(localStorage.getItem(`me`));

    if (!me) {
      return [];
    }
    return me.ranches?.map((r) => r.ranch_uuid);
  }
}
