export const MsInDay = 1000 * 60 * 60 * 24;
export const MsInHour = 1000 * 60 * 60;
export const MarginBetweenEvents = 1000 * 60 * 60;

export const trimSeconds = (date: Date): Date => {
  date.setSeconds(0, 0);
  return date;
};

export const trimSecondsFromIsoString = (dateString: string): string => {
  const date = new Date(dateString);
  return trimSeconds(date).toISOString();
};

export const NewDate = (): Date => trimSeconds(new Date());

export const UtcIsoString = (date: Date): string => {
  if (!date) {
    return null;
  }
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
  ).toISOString();
};

export const DateFromIsoString = (date: string): Date => {
  if (!date) {
    return null;
  }
  const isoDate = new Date(date);
  const result = new Date(
    isoDate.getUTCFullYear(),
    isoDate.getUTCMonth(),
    isoDate.getUTCDate(),
    isoDate.getUTCHours(),
    isoDate.getUTCMinutes(),
    isoDate.getUTCSeconds(),
    isoDate.getUTCMilliseconds()
  );
  return result;
};
