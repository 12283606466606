import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: `[pastureHerdIcon]`,
})
export class HerdIconDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.el.nativeElement, `width`, `30px`);
    this.renderer.setStyle(this.el.nativeElement, `height`, `30px`);
    this.renderer.setStyle(this.el.nativeElement, `border-radius`, `50%`);
    this.renderer.setStyle(this.el.nativeElement, `color`, `white`);
    this.renderer.setStyle(this.el.nativeElement, `vertical-align`, `top`);
    this.renderer.setStyle(this.el.nativeElement, `padding`, `2px`);
  }
}
