import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { NotfoundComponent } from './notfound/notfound.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { PasswordResetRequestComponent } from './password-reset-request/password-reset-request.component';
import { JoinComponent } from './join/join.component';
import { BrowsercheckComponent } from './browsercheck/browsercheck.component';
import { SharedModule } from '../shared/shared.module';
import { MasqueradeComponent } from './masquerade/masquerade.component';
import { SignupMobileComponent } from './signup-mobile/signup-mobile.component';
import { CancelDeleteComponent } from './cancel-delete/cancel-delete.component';
import { CreateRanchComponent } from './create-ranch/create-ranch.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

@NgModule({
  declarations: [
    SignupComponent,
    LoginComponent,
    MasqueradeComponent,
    PasswordResetRequestComponent,
    NotfoundComponent,
    BrowsercheckComponent,
    JoinComponent,
    SignupMobileComponent,
    CancelDeleteComponent,
    CreateRanchComponent,
    PasswordResetComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    RouterModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatIconModule,
    SharedModule,
  ],
})
export class PagesModule {}
