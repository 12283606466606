import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { filter, first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { jwtDecode } from 'jwt-decode';

import { SettingsService } from '../settings/settings.service';
import { LoginModel } from '../../pages/login/login.model';
import { PasswordResetModel } from '../../pages/password-reset-request/password-reset.model';
import { TokenModel, UserModel } from './auth.model';
import { Me } from '../models/user.interface';
import { selectMe } from 'src/app/store/user/user.selectors';
import { GetMe } from 'src/app/store/user/user.actions';
import { AUTH_TOKEN, SEASON_TOKEN } from '../consts/cookie-names.const';

@Injectable({
  providedIn: `root`,
})
export class AuthService {
  constructor(
    private readonly settings: SettingsService,
    private cookieService: CookieService,
    private http: HttpClient,
    private store: Store
  ) {}

  public logout(): void {
    this.unsetAuthData();
    window.location.href = `/`;
  }

  public isLoggedIn(): boolean {
    return !!this.cookieService.get(AUTH_TOKEN);
  }

  public isAdmin(): boolean {
    return !!JSON.parse(localStorage.getItem(`me`))?.is_admin;
  }

  public hasRanch(): boolean {
    return !!JSON.parse(localStorage.getItem(`me`))?.ranches?.length;
  }

  public registerAndLogin(user: any): Observable<any> {
    this.unsetAuthData();
    return this.http.post<any>(`${this.settings.settings.API_V1}/user/register_with_ranch`, user).pipe(
      map((responseUser: any) => {
        this.setAuthData(responseUser.token);
        return responseUser;
      })
    );
  }

  public joinAndLogin(user: any): Observable<any> {
    this.unsetAuthData();
    return this.http.post<any>(`${this.settings.settings.API_V1}/user/register`, user).pipe(
      map((responseUser: any) => {
        this.setAuthData(responseUser.token);
        return responseUser;
      })
    );
  }

  public requestPasswordReset(email: PasswordResetModel): Observable<any> {
    return this.http.post<PasswordResetModel>(`${this.settings.settings.API_V1}/user/password_reset`, email);
  }

  public resetPassword(password: string, token: string): Observable<any> {
    return this.http.patch(
      `${this.settings.settings.API_V1}/user/me`,
      { password },
      { headers: { Authorization: `Token ${token}` } }
    );
  }

  public login(user: LoginModel, masquarade?: boolean): Observable<UserModel> {
    this.unsetAuthData();
    return this.http.post<LoginModel>(`${this.settings.settings.API_V1}/user/login`, user).pipe(
      map((responseUser: any) => {
        this.setAuthData(responseUser.token);
        if (!masquarade) {
          this.store.dispatch(GetMe());
        }
        return responseUser;
      })
    );
  }

  public masquerade(user: any): Observable<UserModel> {
    return this.http.post<any>(`${this.settings.settings.API_V1}/user/masquerade?panel`, user).pipe(
      map((response: any) => {
        this.setAdminData(response.token);
        this.cookieService.delete(SEASON_TOKEN, `/`, this.settings.settings.COOKIE_DOMAIN);
        return response;
      })
    );
  }

  public endMasquerade(): void {
    const adminToken = this.cookieService.get(`authTokenAdminColdstore`);
    this.cookieService.set(`authToken`, adminToken, 600000, `/`, this.settings.settings.COOKIE_DOMAIN);
    this.cookieService.delete(`authTokenAdminColdstore`, `/`, this.settings.settings.COOKIE_DOMAIN);
    window.location.reload();
  }

  public removeAuthData(): void {
    this.cookieService.delete(`authToken`, `/`, this.settings.settings.COOKIE_DOMAIN);
  }

  public getAdminData(): TokenModel {
    const adminToken = this.cookieService.get(`authTokenAdminColdstore`);
    if (!adminToken) {
      return null;
    }
    let token: TokenModel;
    try {
      token = jwtDecode<TokenModel>(adminToken);
    } catch (Error) {
      return null;
    }
    return token;
  }

  public getMe(): Observable<Me> {
    return this.store.select(selectMe).pipe(
      filter((v) => !!v),
      first()
    );
  }

  public createContractedRanch(payload): Observable<any> {
    this.unsetAuthData();
    return this.http.post<any>(`${this.settings.settings.API_V1}/user/register_with_contract`, payload).pipe(
      map((responseUser: any) => {
        this.setAuthData(responseUser.token);
        return responseUser;
      })
    );
  }

  private setAuthData(token: string): void {
    this.cookieService.set(`authToken`, token, 600000, `/`, this.settings.settings.COOKIE_DOMAIN);
  }

  private setAdminData(token: any): void {
    this.cookieService.set(
      `authTokenAdminColdstore`,
      this.cookieService.get(`authToken`),
      600000,
      `/`,
      this.settings.settings.COOKIE_DOMAIN
    );
    this.cookieService.set(`authToken`, token, 600000, `/`, this.settings.settings.COOKIE_DOMAIN);
  }

  private unsetAuthData(): void {
    localStorage.clear();
    this.cookieService.deleteAll(`/`, this.settings.settings.COOKIE_DOMAIN);
  }
}
