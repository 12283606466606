import { Herd, HerdHistory } from 'src/app/shared/models/herd.interface';
import { Animal } from 'src/app/shared/models/animal.model';

export interface State {
  herds: Array<Herd>;
  herdsLoaded: boolean;
  herdHistory: { [id: string]: Array<HerdHistory> };
  herdAnimals: { [id: string]: Array<Animal> };
  herdTerminalAnimals: { [id: string]: Array<Animal> };
}

export const initialState: State = {
  herds: [],
  herdsLoaded: false,
  herdHistory: {},
  herdAnimals: {},
  herdTerminalAnimals: {},
};
