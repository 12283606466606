import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectPaddockName } from 'src/app/store/ranch/ranch/ranch.selectors';

@Pipe({
  name: `paddockName`,
})
export class PaddockNamePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(value: string): Observable<string> {
    return this.store.select(selectPaddockName(value));
  }
}
