import { createReducer, on } from '@ngrx/store';
import { initialState, State } from './ranch.state';
import * as RanchActions from './ranch.actions';

export const ranchReducer = createReducer<State>(
  initialState,
  on(
    RanchActions.GetRanchSuccess,
    (state, { ranch }): State => ({
      ...state,
      ranch,
    })
  ),
  on(
    RanchActions.GetPaddocksSuccess,
    (state, { paddocks }): State => ({
      ...state,
      paddocks,
      paddocksLoaded: true,
      grazeEventsCalculated: false,
    })
  ),
  on(
    RanchActions.GetSeasonsSuccess,
    (state, { seasons }): State => ({
      ...state,
      seasons,
    })
  ),
  on(
    RanchActions.GetInfrastructuresSuccess,
    (state, { infrastructures }): State => ({
      ...state,
      infrastructures,
    })
  ),
  on(
    RanchActions.GrazeEventsCalculated,
    (state, { plannedEvents, historicalEvents, otherPaddockEvents, earliestPossibleMovePerHerd }): State => ({
      ...state,
      plannedEvents,
      historicalEvents,
      otherPaddockEvents,
      earliestPossibleMovePerHerd,
      grazeEventsCalculated: true,
    })
  )
);
