import { trigger, transition, style, animate, state, keyframes } from '@angular/animations';

export const alertAnimations = trigger(`flyInOut`, [
  state(
    `inactive`,
    style({
      opacity: 0,
    })
  ),
  transition(
    `inactive => active`,
    animate(
      `300ms ease-out`,
      keyframes([
        style({
          opacity: 0,
          right: `-300px`,
        }),
        style({
          opacity: 1,
          right: `0`,
        }),
      ])
    )
  ),
  state(
    `active`,
    style({
      opacity: 1,
    })
  ),
  transition(
    `active => removed`,
    animate(
      `300ms ease-out`,
      keyframes([
        style({
          opacity: 1,
          right: `0`,
        }),
        style({
          opacity: 0,
          right: `-600px`,
        }),
      ])
    )
  ),
]);
