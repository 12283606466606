import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: `root`,
})
export class RequestTrackerService {
  private pendingRequestsCount = 0;
  private pendingRequestsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pendingRequests$: Observable<boolean> = this.pendingRequestsSubject.asObservable();

  startRequest(): void {
    this.pendingRequestsCount++;
    this.pendingRequestsSubject.next(true);
  }

  endRequest(): void {
    this.pendingRequestsCount--;
    if (this.pendingRequestsCount === 0) {
      this.pendingRequestsSubject.next(false);
    }
  }

  getPendingRequestsCount(): number {
    return this.pendingRequestsCount;
  }
}
