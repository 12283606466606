<pasture-top-bar [navigationHidden]="true"></pasture-top-bar>
<div class="container-block">
  <img class="logo" src="assets/img/logo.svg" alt="PastureMap" />
  <h1>Join Ranch</h1>
  <mat-card class="padded-card">
    <div>
      You do not have access to any ranches. If you are a member of an existing ranch, ask the ranch account owner to
      add you as a team member.
    </div>
    <div class="sub-action">
      Do you want to register your own ranch?
      <button mat-button color="primary" (click)="createRanch()" [disabled]="isLoading">Click here</button>
    </div>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </mat-card>
</div>
