import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: `[pastureLabel]`,
})
export class LabelDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.el.nativeElement, `color`, `#8298b4`);
    this.renderer.setStyle(this.el.nativeElement, `font-size`, `12px`);
    this.renderer.setStyle(this.el.nativeElement, `font-weight`, 500);
  }
}
