import { UserSubscription } from '../../shared/models/user-subscription.interface';
import { TeamMember, Me } from '../../shared/models/user.interface';

export interface State {
  me: Me;
  sub: UserSubscription;
  subLoaded: boolean;
  ranchTeam: Array<TeamMember>;
}

export const initialState: State = {
  me: null,
  sub: null,
  subLoaded: false,
  ranchTeam: [],
};
