import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: `pasture-editable-text`,
  templateUrl: `./editable-text.component.html`,
  styleUrls: [`./editable-text.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableTextComponent {
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Input() edit: boolean;
  @Input() apperance: MatFormFieldAppearance;
  @Input() type: `default` | `date` | `textarea` = `default`;
  @Input() placeholder: string;
}
