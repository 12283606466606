import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { selectRanch } from 'src/app/store/ranch/ranch/ranch.selectors';

export const Unit = {
  Ad: `ad`,
  Adpac: `ad/ac`,
  Lb: `lb`,
  Lbpac: `lb/ac`,
  Aum: `aum`,
} as const;
export type Unit = (typeof Unit)[keyof typeof Unit];

@Pipe({
  name: `unit`,
  pure: false,
})
@UntilDestroy()
export class UnitPipe implements PipeTransform {
  private animalUnit: number;
  private dailyFeedIntakePercent: number;

  private get animalDay(): number {
    return (this.dailyFeedIntakePercent / 100) * this.animalUnit;
  }

  constructor(store: Store) {
    store
      .select(selectRanch)
      .pipe(
        filter((r) => !!r),
        untilDestroyed(this)
      )
      .subscribe((r) => {
        this.animalUnit = r.standard_animal_unit;
        this.dailyFeedIntakePercent = r.standard_daily_feed_intake_percent;
      });
  }

  transform(value: number, from: Unit, to: Unit, acrage: number): any {
    if (value && from && to && acrage) {
      if (from === to) {
        return value;
      }

      let lbValue: number;
      switch (from) {
        case `ad`:
          lbValue = value * this.animalDay;
          break;
        case `ad/ac`:
          lbValue = value * this.animalDay * acrage;
          break;
        case `lb`:
          lbValue = value;
          break;
        case `lb/ac`:
          lbValue = value * acrage;
          break;
        case `aum`:
          lbValue = value * this.animalDay * 30;
          break;
      }

      let result: number;
      switch (to) {
        case `ad`:
          result = lbValue / this.animalDay;
          break;
        case `ad/ac`:
          result = lbValue / this.animalDay / acrage;
          break;
        case `lb`:
          result = lbValue;
          break;
        case `lb/ac`:
          result = lbValue / acrage;
          break;
        case `aum`:
          result = lbValue / this.animalDay / 30;
          break;
      }
      return result;
    }
    return value;
  }
}
