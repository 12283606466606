import { trigger, transition, style, query, group, animate } from '@angular/animations';

export const pagesAnimations = trigger(`routeAnimations`, [
  transition(`* <=> *`, [
    query(`:enter, :leave`, style({ position: `fixed`, width: `100%`, top: 0, opacity: 1, height: `100%` }), {
      optional: true,
    }),
    group([
      query(
        `:enter`,
        [
          style({ transform: `translateX(100%)`, opacity: 0 }),
          animate(`0.5s ease-in-out`, style({ transform: `translateX(0%)`, opacity: 1 })),
        ],
        { optional: true }
      ),
      query(
        `:leave`,
        [
          style({ transform: `translateX(0%)`, opacity: 1 }),
          animate(`0.5s ease-in-out`, style({ transform: `translateX(-100%)`, opacity: 0 })),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);
