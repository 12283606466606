import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';

import { SettingsUserService } from 'src/app/settings/settings-user.service';
import { GetMe } from 'src/app/store/user/user.actions';
import { selectMe } from 'src/app/store/user/user.selectors';
import { Me } from '../models/user.interface';
import { selectSeasons } from 'src/app/store/ranch/ranch/ranch.selectors';
import { Season } from '../models/season.interface';
import { GetRanch, GetSeasons } from 'src/app/store/ranch/ranch/ranch.actions';
import { SetUnits } from 'src/app/store/settings/settings.actions';

@Injectable({
  providedIn: `root`,
})
export class RanchWithSeasonResolver {
  constructor(private readonly sus: SettingsUserService, private readonly store: Store) {}

  public async resolve(): Promise<any> {
    let me = await firstValueFrom(this.store.select(selectMe));
    let seasons = await firstValueFrom(this.store.select(selectSeasons));

    if (me && seasons?.length) {
      return;
    }
    if (!me) {
      this.store.dispatch(GetMe());
      me = await firstValueFrom(this.store.select(selectMe).pipe(filter((me) => !!me)));
    }
    if (!seasons?.length) {
      this.store.dispatch(GetSeasons());
      seasons = await firstValueFrom(this.store.select(selectSeasons).pipe(filter((seasons) => !!seasons?.length)));
    }
    const season = seasons.find((s) => s.uuid === this.sus.getSeasonIdFromStorage()) ?? seasons[seasons.length - 1];
    this.setUUIDs(me, season);
    this.store.dispatch(SetUnits({ units: me.pref_imperial_units ? `imperial` : `metric` }));
    this.store.dispatch(GetRanch());
  }

  private setUUIDs(user: Me, seasonFromMemory: Season): void {
    if (seasonFromMemory && user.ranches.some((r) => r.ranch_uuid === seasonFromMemory.ranch_uuid)) {
      this.sus.setRanchUUID(this.sus.checkRanchUUID(seasonFromMemory.ranch_uuid));
      this.sus.setSeasonUUID(seasonFromMemory.uuid);
    } else {
      this.sus.setRanchUUID(this.sus.checkRanchUUID(user.ranches[0].ranch_uuid));
      this.sus.setSeasonUUID(user.ranches[0].current_season_uuid);
    }
  }
}
