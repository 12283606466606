import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: `[pastureNewTag]`,
})
export class NewTagDirective implements AfterViewInit {
  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit(): void {
    this.el.nativeElement.innerHTML =
      this.el.nativeElement.innerHTML +
      `<span class='new-tag' style='
        color: #EFAF33; 
        position: absolute;
        top: 8px;
        right: 8px;
        font-weight: 500;
      '>NEW</span>`;
  }
}
