import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: `relativeDate`,
  pure: true,
})
export class RelativeDatePipe implements PipeTransform {
  private readonly intervals: { [key: string]: number } = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  transform(value: any, sinceNow = true, numberValueOnly = false): any {
    if (value) {
      const secondsSince = Math.floor(((sinceNow ? Date.now() : 0) - +new Date(value)) / 1000);
      let counter;
      for (const i in this.intervals) {
        counter = Math.floor(Math.abs(secondsSince) / this.intervals[i]);
        if (counter > 0) {
          let inWords: string;
          if (counter === 1) {
            inWords = counter + ` ` + i;
          } else {
            inWords = counter + ` ` + i + `s`;
          }

          if (numberValueOnly) {
            return inWords;
          }
          if (secondsSince > 0) {
            return `${inWords} ago`;
          } else {
            return `in ${inWords}`;
          }
        }
      }
    }
    return value;
  }
}
