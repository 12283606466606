<div class="mobile-page">
  <div class="logo-container">
    <img class="logo" src="../../assets/img/logo.svg" alt="PastureMap" />
    <button mat-button color="primary" (click)="confirmMobileUsage()" class="continue-web">
      continue to mobile site
    </button>
  </div>
  <h1>The best mobile experience is in the App</h1>
  <p>Many features like offline mode, photos and herd move notifications are only available in the App.</p>
  <button mat-raised-button (click)="download()" color="primary" class="big-wide-button">Download Now</button>

  <div class="bottom-container">
    <div class="bg"></div>
    <div class="mobile-device">
      <div *ngIf="deviceInfo.os === 'iOS'" class="phone iphone"></div>
      <div *ngIf="deviceInfo.os !== 'iOS'" class="phone android"></div>
    </div>
  </div>
</div>
