import { Component, EventEmitter, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: `pasture-mobile`,
  templateUrl: `./mobile.component.html`,
  styleUrls: [`./mobile.component.scss`],
})
export class MobileComponent {
  @Output() confirmedToUseWeb = new EventEmitter<boolean>();
  public readonly deviceInfo: any;

  constructor(private deviceService: DeviceDetectorService) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  public download(): void {
    if (this.deviceInfo.os === `Android`) {
      window.location.href = `https://play.google.com/store/apps/details?id=com.pasturemap`;
    } else if (this.deviceInfo.os === `iOS`) {
      window.location.href = `https://apps.apple.com/us/app/pasturemap/id1664810460`;
    } else {
      alert(`Sorry! But your mobile device is not supported for now`);
    }
  }

  public confirmMobileUsage(): void {
    this.confirmedToUseWeb.emit(true);
  }
}
