import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { selectTotalAnimalCount } from 'src/app/store/ranch/livestock/livestock.selectors';
import { selectMaxAnimalCount } from 'src/app/store/ranch/ranch/ranch.selectors';

@Injectable()
export class AnimalCountValidator implements Validator {
  private animalCount$ = new BehaviorSubject<number>(0);
  private maxAnimals$ = new BehaviorSubject<number>(0);

  constructor(store: Store) {
    store.select(selectTotalAnimalCount).subscribe(this.animalCount$);
    store.select(selectMaxAnimalCount).subscribe(this.maxAnimals$);
    this.validate = this.validate.bind(this);
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    const animalCount = this.animalCount$.value;
    const maxAnimals = this.maxAnimals$.value;
    if (parseInt(control.value) === 0) {
      return null;
    }
    if (animalCount > maxAnimals) {
      return {
        exceededAnimalQuota: `Exceeded animal limit. Remove animals or upgrade your plan.`,
      };
    }
    if (animalCount + control.value > maxAnimals) {
      return {
        exceededAnimalQuota: `You can add only ${maxAnimals - animalCount} more.`,
      };
    }
    return null;
  }
}
