import { Pipe, PipeTransform } from '@angular/core';

type Order = `asc` | `desc`;

@Pipe({
  name: `orderBy`,
})
export class OrderByPipe<T> implements PipeTransform {
  transform(array: T[], iteratee: keyof T, order: Order = `asc`): T[] {
    return array.sort((a: T, b: T) => this.compare(a, b, iteratee, order));
  }

  private compare(a: T, b: T, iteratee: keyof T, order: Order): number {
    if (a[iteratee] < b[iteratee]) {
      return order === `asc` ? -1 : 1;
    } else if (a[iteratee] > b[iteratee]) {
      return order === `asc` ? 1 : -1;
    }
    return 0;
  }
}
