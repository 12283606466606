import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: `[pastureMaxWidthText]`,
})
export class MaxWidthTextDirective implements OnInit {
  @Input(`pastureMaxWidthText`) maxEllipsisWidth: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.maxEllipsisWidth) {
      this.renderer.setStyle(this.el.nativeElement, `max-width`, this.maxEllipsisWidth);
    }

    this.renderer.setStyle(this.el.nativeElement, `text-overflow`, `ellipsis`);
    this.renderer.setStyle(this.el.nativeElement, `white-space`, `nowrap`);
    this.renderer.setStyle(this.el.nativeElement, `display`, `inline-block`);
    this.renderer.setStyle(this.el.nativeElement, `overflow`, `hidden`);
  }
}
