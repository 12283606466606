import { Pipe, PipeTransform } from '@angular/core';
import { MsInDay } from '../constants/time.constant';

@Pipe({
  name: `toMs`,
})
export class DaysToMsPipe implements PipeTransform {
  transform(days: number): number {
    return days * MsInDay;
  }
}
