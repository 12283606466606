<img class="logo" src="assets/img/logo.svg" alt="PastureMap" />
<div class="main-container">
  <div class="right">
    <mat-card *ngIf="isLoading">
      <div class="padded-spinner">
        <mat-spinner diameter="90"></mat-spinner>
      </div>
    </mat-card>
    <mat-card *ngIf="!isLoading" class="padded-card">
      <form [formGroup]="signupForm" autocomplete="on" class="general-form" novalidate (ngSubmit)="signIn()">
        <mat-form-field appearance="outline" class="full-width">
          <label class="abs-top">Full Name</label>
          <input (keyup)="setRanchName()" type="text" required="required" matInput formControlName="fullName" />
          <mat-error *ngIf="hasError('fullName', 'required')">
            <i class="fal fa-exclamation-triangle"></i>
            Name is required
          </mat-error>
          <mat-error *ngIf="hasError('fullName', 'maxlength')">
            <i class="fal fa-exclamation-triangle"></i>
            Your name is toooooo long
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <label class="abs-top">Ranch Name</label>
          <input type="text" required="required" matInput formControlName="name" />
          <mat-error *ngIf="hasError('name', 'required')">
            <i class="fal fa-exclamation-triangle"></i>
            Ranch name is required
          </mat-error>
          <mat-error *ngIf="hasError('name', 'name')">
            <i class="fal fa-exclamation-triangle"></i>
            Ranch name is toooooo long
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <label class="abs-top">Email</label>
          <input type="email" required="required" matInput formControlName="email" />
          <mat-error *ngIf="hasError('email', 'required')">
            <i class="fal fa-exclamation-triangle"></i>
            Email is required
          </mat-error>
          <mat-error *ngIf="hasError('email', 'email')">
            <i class="fal fa-exclamation-triangle"></i>
            Email address contain errors
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <label class="abs-top">Password</label>
          <input
            [type]="showPassword ? 'text' : 'password'"
            required="required"
            class="password-input"
            matInput
            formControlName="password"
          />
          <button
            type="button"
            class="toggle-password"
            mat-icon-button
            matSuffix
            (click)="showPassword = !showPassword"
          >
            <mat-icon *ngIf="showPassword">visibility_off</mat-icon>
            <mat-icon *ngIf="!showPassword">visibility</mat-icon>
          </button>
          <mat-hint *ngIf="!signupForm.controls.password.touched">At least eight characters long</mat-hint>
          <mat-error *ngIf="hasError('password', 'required')">
            <i class="fal fa-exclamation-triangle"></i>
            Password is required
          </mat-error>
          <mat-error *ngIf="hasError('password', 'passwordStrength')">
            <i class="fal fa-exclamation-triangle"></i>
            {{ signupForm.get('password').errors['passwordStrength'] }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <label class="abs-top">Phone Number</label>
          <input type="text" required="required" matInput formControlName="phone" />
          <mat-error *ngIf="hasError('phone', 'required')">
            <i class="fal fa-exclamation-triangle"></i>
            Phone number is required
          </mat-error>
          <mat-error *ngIf="hasError('phone', 'name')">
            <i class="fal fa-exclamation-triangle"></i>
            Phone name is toooooo long
          </mat-error>
        </mat-form-field>

        <div class="top-action">
          By clicking Start Free Trial, you are agreeing to PastureMap's
          <a target="_blank" href="https://grassrootscarbon.com/pasture-map/terms-of-service/">Terms of Service</a>
          and
          <a target="_blank" href="https://grassrootscarbon.com/pasture-map/privacy-policy/">Privacy Policy</a>
        </div>

        <div class="full-width">
          <button class="big-wide-button sign-in" type="submit" mat-raised-button color="primary">
            Start Free Trial
          </button>
        </div>
      </form>
      <div class="sub-action">
        Already have an account?
        <a class="login" routerLink="/login" routerLinkActive="active">Log In</a>
      </div>
    </mat-card>
  </div>
</div>
