import { Ranch } from 'src/app/shared/models/ranch.interface';
import { Infrastructure } from 'src/app/shared/models/inventory.interface';
import { Paddock, SingleHerdPaddockHistory } from 'src/app/shared/models/paddock.interface';
import { Season } from 'src/app/shared/models/season.interface';
import { GrazeEvent } from 'src/app/shared/models/graze-event.model';

export interface State {
  ranch: Ranch;
  paddocks: Array<Paddock>;
  paddocksLoaded: boolean;
  seasons: Array<Season>;
  infrastructures: Array<Infrastructure>;
  plannedEvents: Array<GrazeEvent>;
  historicalEvents: Array<GrazeEvent>;
  otherPaddockEvents: Array<SingleHerdPaddockHistory>;
  earliestPossibleMovePerHerd: { [id: string]: number };
  grazeEventsCalculated: boolean;
}

export const initialState: State = {
  ranch: null,
  paddocks: [],
  paddocksLoaded: false,
  seasons: [],
  infrastructures: [],
  plannedEvents: [],
  historicalEvents: [],
  otherPaddockEvents: [],
  earliestPossibleMovePerHerd: {},
  grazeEventsCalculated: false,
};
