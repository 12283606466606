import { createAction, props } from '@ngrx/store';
import { Animal } from 'src/app/shared/models/animal.model';
import { Herd, HerdWithHistory } from 'src/app/shared/models/herd.interface';

export enum Types {
  // herds
  GetHerds = `[Livestock] Get herds`,
  GetHerdsSuccess = `[Livestock] Get herds success`,
  GetHerdsFailure = `[Livestock] Get herds failure`,

  GetHerd = `[Livestock] Get herd`,
  GetHerdSuccess = `[Livestock] Get herd success`,
  GetHerdFailure = `[Livestock] Get herd failure`,

  GetHerdHistory = `[Livestock] Get herd history`,
  GetHerdHistorySuccess = `[Livestock] Get herd history success`,
  GetHerdHistoryFailure = `[Livestock] Get herd history failure`,

  // animals
  GetHerdAnimals = `[Livestock] Get herd animals`,
  GetHerdAnimalsSuccess = `[Livestock] Get herd animals success`,
  GetHerdAnimalsFailure = `[Livestock] Get herd animals failure`,

  GetTerminalHerdAnimalsSuccess = `[Livestock] Get terminal herd animals success`,
  GetTerminalHerdAnimalsFailure = `[Livestock] Get terminal herd animals failure`,
}

// herds
export const GetHerds = createAction(Types.GetHerds);
export const GetHerdsSuccess = createAction(Types.GetHerdsSuccess, props<{ herds: Array<Herd> }>());
export const GetHerdsFailure = createAction(Types.GetHerdsFailure);

export const GetHerd = createAction(Types.GetHerd, props<{ id: string }>());
export const GetHerdSuccess = createAction(Types.GetHerdSuccess, props<{ herd: Herd }>());
export const GetHerdFailure = createAction(Types.GetHerdFailure);

export const GetHerdHistory = createAction(Types.GetHerdHistory, props<{ herdId: string }>());
export const GetHerdHistorySuccess = createAction(Types.GetHerdHistorySuccess, props<{ herd: HerdWithHistory }>());
export const GetHerdHistoryFailure = createAction(Types.GetHerdHistoryFailure);

// animals
export const GetHerdAnimals = createAction(Types.GetHerdAnimals, props<{ id: string }>());
export const GetHerdAnimalsSuccess = createAction(
  Types.GetHerdAnimalsSuccess,
  props<{ herdId: string; animals: Array<Animal> }>()
);
export const GetHerdAnimalsFailure = createAction(Types.GetHerdAnimalsFailure, props<{ herdId: string }>());

export const GetTerminalHerdAnimalsSuccess = createAction(
  Types.GetTerminalHerdAnimalsSuccess,
  props<{ herdId: string; animals: Array<Animal> }>()
);
export const GetTerminalHerdAnimalsFailure = createAction(
  Types.GetTerminalHerdAnimalsFailure,
  props<{ herdId: string }>()
);
