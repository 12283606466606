import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SettingsUserService } from 'src/app/settings/settings-user.service';
import { UserSubscription } from 'src/app/shared/models/user-subscription.interface';
import { TeamMember, User } from 'src/app/shared/models/user.interface';
import { Me } from 'src/app/shared/models/user.interface';
import { SettingsService } from 'src/app/shared/settings/settings.service';

@Injectable({
  providedIn: `root`,
})
export class UserService {
  constructor(
    private readonly settings: SettingsService,
    private readonly http: HttpClient,
    private readonly sus: SettingsUserService
  ) {}

  public getMe(): Observable<Me> {
    return this.http.get<Me>(`${this.settings.settings.API_V1}/user/me?include_multiple_ranches=true`);
  }

  public getSub(): Observable<UserSubscription> {
    return this.sus.getRanchUUID()
      ? this.http.get<UserSubscription>(`${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/payment`)
      : of(null);
  }

  public getRanchTeam(): Observable<Array<TeamMember>> {
    return this.sus.getRanchUUID()
      ? this.http.get<Array<TeamMember>>(`${this.settings.settings.API_V1}/ranch/${this.sus.ranchUUID}/user`)
      : of([]);
  }

  public getHerds(): Observable<User> {
    return this.http.get<User>(`${this.settings.settings.API_V1}/user/me?include_multiple_ranches=true`);
  }
}
