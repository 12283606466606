import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../settings/settings.service';

@Component({
  selector: `pasture-navigation`,
  templateUrl: `./navigation.component.html`,
  styleUrls: [`./navigation.component.scss`],
})
export class NavigationComponent implements OnInit {
  public oldURL = ``;
  public newURL = ``;
  public isAdmin: boolean;

  public navItems: { baseURL: string; path: string; name: string }[] = [];

  constructor(private readonly settingsService: SettingsService) {}

  ngOnInit(): void {
    this.oldURL = this.settingsService.settings.ORIG_SITE;
    this.newURL = this.settingsService.settings.NEW_SITE;
    this.isAdmin = !!JSON.parse(localStorage.getItem(`me`))?.is_admin;
    this.fillNavItems();
  }

  public fillNavItems(): void {
    this.navItems = [
      { baseURL: this.newURL, path: `/map`, name: `Map` },
      { baseURL: this.oldURL, path: `/pastures/list`, name: `Pastures` },
      { baseURL: this.oldURL, path: `/grazing-chart`, name: `Grazing Chart` },
      { baseURL: this.newURL, path: `/herds`, name: `Herds` },
      { baseURL: this.newURL, path: `/todo`, name: `Todo` },
    ];
  }
}
