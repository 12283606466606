import { AbstractControl, ValidationErrors } from '@angular/forms';

export function NumberValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;

  const parsedValue = parseFloat(value);

  if (isNaN(parsedValue)) {
    return { notANumber: true };
  }

  return null;
}
