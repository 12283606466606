import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { alertAnimations } from '../animations/alert.animations';

@Component({
  selector: `pasture-alert`,
  styleUrls: [`./alert.component.scss`],
  templateUrl: `./alert.component.html`,
  animations: [alertAnimations],
})
export class AlertComponent extends Toast {
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
    // this.playSound();
  }

  private playSound(): void {
    let audio = new Audio(`../../../assets/sound/alert.mp3`);
    audio.load();
    audio.play().then();
    audio.onended = () => {
      audio = null;
    };
  }
}
