import { createReducer, on } from '@ngrx/store';
import { initialState, State } from './settings.state';
import * as SettingActions from './settings.actions';

export const settingsReducer = createReducer<State>(
  initialState,
  on(
    SettingActions.GetUnits,
    (state): State => ({
      ...state,
      units: (localStorage.getItem(`units`) as `imperial` | `metric`) ?? `imperial`,
    })
  ),
  on(SettingActions.SetUnits, (state, { units }): State => {
    localStorage.setItem(`units`, units);
    return {
      ...state,
      units,
    };
  })
);
