import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AlertService } from '../alert/alert.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: `root`,
})
export class AuthGuard {
  constructor(private router: Router, private alert: AlertService, private authService: AuthService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isLoggedIn()) {
      return true;
    }
    this.router.navigate([`/login`], { queryParams: { returnUrl: state.url } }).then(() => {
      this.alert.showError(`You are not logged in`, `Sorry!`);
    });
    return false;
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  public isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }
}
