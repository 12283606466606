import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: `pasture-table-column-editor`,
  templateUrl: `./table-column-editor.component.html`,
  styleUrls: [`./table-column-editor.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnEditorComponent implements OnInit {
  public columnConfiguration: Array<ConfigEntry>;
  @Input() allColumns: Array<{ value: string; displayName: string }>;
  @Input() savedColumnConfiguration: Array<ConfigEntry>;
  @Output() columnConfigurationChange = new EventEmitter<Array<ConfigEntry>>();
  public get visibleColumns(): Array<string> {
    return this.columnConfiguration.filter((c) => c.visible).map((c) => c.col);
  }
  @Output() visibleColumnsChange = new EventEmitter<Array<string>>();

  ngOnInit(): void {
    this.columnConfiguration =
      this.savedColumnConfiguration ?? this.allColumns.map((c) => ({ col: c.value, visible: true }));
    this.emitEvents();
  }

  public drop(event: CdkDragDrop<ConfigEntry[]>): void {
    moveItemInArray(this.columnConfiguration, event.previousIndex, event.currentIndex);
    this.emitEvents();
  }

  public reset(): void {
    this.columnConfiguration = this.allColumns.map((c) => ({ col: c.value, visible: true }));
    this.emitEvents();
  }

  public emitEvents(): void {
    this.visibleColumnsChange.emit(this.visibleColumns);
    this.columnConfigurationChange.emit(this.columnConfiguration);
  }

  public visibleName(value: string): string {
    return this.allColumns.find((c) => c.value === value)?.displayName ?? value;
  }
}

type ConfigEntry = { col: string; visible: boolean };
