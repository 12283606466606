import { Component, OnInit } from '@angular/core';
import { PasswordResetModel } from './password-reset.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../shared/alert/alert.service';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth/auth.service';
import { CustomValidators } from 'src/app/shared/form-validators/custom-validators.class';

@Component({
  selector: `pasture-password-reset-request`,
  templateUrl: `./password-reset-request.component.html`,
  styleUrls: [`./password-reset-request.component.scss`],
})
export class PasswordResetRequestComponent implements OnInit {
  public isLoading: boolean;
  public passwordResetForm: FormGroup;

  constructor(private alertService: AlertService, private router: Router, private authService: AuthService) {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.passwordResetForm = new FormGroup({
      email: new FormControl(``, [Validators.required, CustomValidators.email]),
    });
  }

  public hasError = (controlName: string, errorName: string): boolean => {
    return this.passwordResetForm.controls[controlName].hasError(errorName);
  };

  public resetPassword(email: PasswordResetModel): void {
    if (this.passwordResetForm.valid) {
      this.isLoading = true;
      this.authService.requestPasswordReset(email).subscribe({
        next: () => {
          this.router.navigate([`login`]);
          this.alertService.showInfo(
            `If your email was registered in our system - you will get password reset link`,
            ``
          );
        },
        error: (errorResponse: any) => {
          this.isLoading = false;
          this.alertService.showError(errorResponse.error.error, ``);
        },
      });
    }
  }
}
