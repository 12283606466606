import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { selectUnits } from 'src/app/store/settings/settings.selectors';

@Injectable({
  providedIn: `root`,
})
export class UnitPersistencyService {
  private unitSubject: BehaviorSubject<`imperial` | `metric`>;
  public get unit(): `imperial` | `metric` {
    return this.unitSubject.value;
  }

  constructor(private store: Store) {
    this.unitSubject = new BehaviorSubject<any>(`imperial`);

    this.store.select(selectUnits).subscribe(this.unitSubject);
  }
}
