<div class="nav-card">
  <div class="nav-container">
    <a class="image-link big" href="{{ oldURL }}"><img src="assets/img/logo.svg" alt="PastureMap" /></a>
    <a class="image-link small" href="{{ oldURL }}"><img src="assets/img/logo_small.svg" alt="PastureMap" /></a>
    <mat-nav-list disableRipple="true" class="extended-navigation">
      <ng-container *ngFor="let item of navItems">
        <ng-container *ngTemplateOutlet="singleNavLink; context: { item }"></ng-container>
      </ng-container>
    </mat-nav-list>
    <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-container *ngFor="let item of navItems">
    <ng-container *ngTemplateOutlet="singleMenuLink; context: { item }"></ng-container>
  </ng-container>
</mat-menu>

<ng-template #singleNavLink let-item="item">
  <a *ngIf="item.baseURL === oldURL" mat-list-item [href]="oldURL + item.path">
    <span>{{ item.name }}</span>
  </a>
  <a *ngIf="item.baseURL === newURL" mat-list-item [routerLink]="[item.path]" routerLinkActive="active">
    <span>{{ item.name }}</span>
  </a>
</ng-template>

<ng-template #singleMenuLink let-item="item">
  <a *ngIf="item.baseURL === oldURL" mat-menu-item [href]="oldURL + item.path">
    <span>{{ item.name }}</span>
  </a>
  <a *ngIf="item.baseURL === newURL" mat-menu-item [routerLink]="[item.path]" routerLinkActive="active">
    <span>{{ item.name }}</span>
  </a>
</ng-template>
