import { createReducer, on } from '@ngrx/store';
import { initialState, State } from './livestock.state';
import * as LivestockActions from './livestock.actions';

export const livestockReducer = createReducer<State>(
  initialState,
  on(
    LivestockActions.GetHerdsSuccess,
    (state, { herds }): State => ({
      ...state,
      herds,
      herdsLoaded: true,
    })
  ),
  on(
    LivestockActions.GetHerdSuccess,
    (state, { herd }): State => ({
      ...state,
      herds: [...state.herds.filter((h) => h.uuid !== herd.uuid), herd],
    })
  ),
  on(
    LivestockActions.GetHerdHistorySuccess,
    (state, { herd }): State => ({
      ...state,
      herdHistory: { ...state.herdHistory, [herd.uuid]: herd.history },
    })
  ),
  on(
    LivestockActions.GetHerdAnimalsSuccess,
    (state, { herdId, animals }): State => ({
      ...state,
      herdAnimals: { ...state.herdAnimals, [herdId]: animals },
    })
  ),
  on(
    LivestockActions.GetTerminalHerdAnimalsSuccess,
    (state, { herdId, animals }): State => ({
      ...state,
      herdTerminalAnimals: { ...state.herdTerminalAnimals, [herdId]: animals },
    })
  )
);
