<div class="notify-message {{toastClasses}}">
  <div class="left-side">
    <div class="animated-bar">
      <div class="icon"></div>
    </div>
  </div>
  <div class="right-side">
    <div class="title" *ngIf="title">
      {{ title }}
    </div>
    <div class="message" *ngIf="message">
      {{ message }}
    </div>
  </div>
  <div *ngIf="options.closeButton" (click)="remove()" class="close-me"></div>
  <ng-container *ngIf="options.progressBar && !options.disableTimeOut">
    <div class="progress" [style.width]="width + '%'"></div>
  </ng-container>
</div>
