import { Routes } from '@angular/router';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { BrowsercheckComponent } from './pages/browsercheck/browsercheck.component';
import { JoinComponent } from './pages/join/join.component';
import { LoginComponent } from './pages/login/login.component';
import { MasqueradeComponent } from './pages/masquerade/masquerade.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { PasswordResetRequestComponent } from './pages/password-reset-request/password-reset-request.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SignupMobileComponent } from './pages/signup-mobile/signup-mobile.component';
import { RanchWithSeasonResolver } from './shared/resolvers/ranch-with-season-resolver.service';
import { CancelDeleteComponent } from './pages/cancel-delete/cancel-delete.component';
import { CreateRanchComponent } from './pages/create-ranch/create-ranch.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';

export const appRoutes: Routes = [
  {
    path: `panel`,
    loadChildren: () => import(`./admin/admin.module`).then((m) => m.AdminModule),
    data: { specialView: `admin` },
  },
  { path: `cancel_delete`, component: CancelDeleteComponent },
  {
    path: ``,
    component: MainLayoutComponent,
    children: [
      {
        path: `settings`,
        loadChildren: () => import(`./settings/settings.module`).then((m) => m.SettingsModule),
        resolve: { season: RanchWithSeasonResolver },
      },
      {
        path: `onboarding`,
        loadChildren: () => import(`./onboarding/onboarding.module`).then((m) => m.OnboardingModule),
        resolve: { season: RanchWithSeasonResolver },
      },
      {
        path: `todo`,
        loadChildren: () => import(`./todo/todo.module`).then((m) => m.TodoModule),
        resolve: { season: RanchWithSeasonResolver },
      },
      {
        path: `map`,
        loadChildren: () => import(`./map/map.module`).then((m) => m.MapModule),
        resolve: { season: RanchWithSeasonResolver },
      },
      { path: ``, component: SignupComponent, data: { animation: `Home` }, pathMatch: `full` },
      { path: `mobile-join`, component: SignupMobileComponent, data: { animation: `Home` }, pathMatch: `full` },
      { path: `too-old`, component: BrowsercheckComponent, data: { animation: `Login` } },
      { path: `login`, component: LoginComponent, data: { animation: `Login` } },
      { path: `admin`, component: MasqueradeComponent, data: { animation: `Login` } },
      { path: `join`, component: JoinComponent, data: { animation: `Login` } },
      {
        path: `password-reset-request`,
        component: PasswordResetRequestComponent,
        data: { animation: `PasswordReset` },
      },
      {
        path: `password-reset`,
        component: PasswordResetComponent,
        data: { animation: `PasswordReset` },
      },
      {
        path: `join-ranch`,
        component: CreateRanchComponent,
        canActivate: [AuthGuard],
        resolve: { season: RanchWithSeasonResolver },
      },
      {
        path: `subscribe`,
        loadChildren: () => import(`./subscription/subscription.module`).then((m) => m.SubscriptionModule),
      },
      {
        path: `herds`,
        loadChildren: () => import(`./herds/herds.module`).then((m) => m.HerdsModule),
      },
      { path: `**`, component: NotfoundComponent },
    ],
  },
];
