<div class="top-bar-anchor">
  <div class="top-bar-container" [class.scrolled]="scrolled">
    <div class="navigation-container">
      <pasture-navigation *ngIf="!navigationHidden"></pasture-navigation>
    </div>
    <div>
      <pasture-user-menu
        *ngIf="!userHidden"
        [whiteUserButton]="whiteUserButton"
        [linksOnly]="linksOnly"
      ></pasture-user-menu>
    </div>
  </div>
</div>
